import { useMediaQuery } from "react-responsive";
import manChating from "../../assets/avatar3.webp";
import message from "../../assets/pm1.webp";
import message2 from "../../assets/pm2.webp";
import message3 from "../../assets/pm3.webp";
import message4 from "../../assets/pm4.webp";
import message5 from "../../assets/pm5.webp";
import message6 from "../../assets/pm6.webp";
import { useInView } from "framer-motion";
import { useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function AIchatBot() {
  const ref = useRef(null);
  const inInView = useInView(ref, { once: true });

  const isExtraMiniWidth = useMediaQuery({ query: "(min-width: 280px)" });
  const isMidWidth = useMediaQuery({ query: "(min-width: 768px)" });
  const isLargeWidth = useMediaQuery({ query: "(min-width: 1024px)" });
  const isExtraWidth = useMediaQuery({ query: "(min-width: 1280px)" });

  return (
    <section className="max-h-[800px] h-screen flex items-center bg-backBlue overflow-hidden">
      <div
        ref={ref}
        className="max-w-7xl px-4 h-full mx-auto grid lg:flex overflow-hidden items-center">
        <div className="w-full lg:w-1/2 text-center py-5 lg:py-0 lg:text-start xl:w-1/3 ">
          <h2 className="font-bold text-center lg:text-start text-2xl lg:text-3xl xl:text-5xl py-8">
            Ai Chat Bot
          </h2>
          <p className="font-normal w-[80%] mx-auto text-sm lg:text-base md:text-base lg:mx-0 lg:w-[80%] xl:w-full">
            Our AI-powered chatbot revolutionizes customer interactions by
            providing intelligent, automated assistance. With natural language
            processing and machine learning capabilities, it offers personalized
            responses, instant support, and seamless engagement, delivering a
            superior and efficient customer service experience.
          </p>
        </div>
        <div className="relative w-full h-full lg:w-1/2 xl:w-2/3">
          <div className="w-fit sm:w-[70%] md:w-[50%] flex items-end h-full relative bottom-0 md:bottom-0 left-1/2 -translate-x-1/2">
            <LazyLoadImage
              className="w-full"
              effect="blur"
              src={manChating}
              alt="manChating"
            />
          </div>
          <div
            ref={ref}
            style={
              isExtraWidth
                ? {
                    bottom: inInView ? "40%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isLargeWidth
                ? {
                    bottom: inInView ? "40%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isMidWidth
                ? {
                    bottom: inInView ? "45%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isExtraMiniWidth
                ? {
                    bottom: inInView ? "40%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : {}
            }
            className="absolute justify-end lg:justify-center w-fit right-0 flex gap-5">
            <img
              src={message}
              className="w-[70%] sm:w-[70%] md:w-[80%] lg:w-full"
              alt="massage"
            />
          </div>
          <div
            ref={ref}
            style={
              isExtraWidth
                ? {
                    bottom: inInView ? "30%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isLargeWidth
                ? {
                    bottom: inInView ? "30%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isMidWidth
                ? {
                    bottom: inInView ? "35%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isExtraMiniWidth
                ? {
                    bottom: inInView ? "30%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : {}
            }
            className="absolute lg:-left-10 xl:left-10 flex gap-5">
            <img
              src={message2}
              className="w-[70%] sm:w-[80%] lg:w-full"
              alt="massage"
            />
          </div>
          <div
            ref={ref}
            style={
              isExtraWidth
                ? {
                    bottom: inInView ? "23%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isLargeWidth
                ? {
                    bottom: inInView ? "23%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isMidWidth
                ? {
                    bottom: inInView ? "25%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isExtraMiniWidth
                ? {
                    bottom: inInView ? "22%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : {}
            }
            className="absolute justify-end lg:justify-center right-0 flex gap-5">
            <img
              src={message3}
              className="w-[70%] sm:w-[80%] lg:w-full"
              alt="massage"
            />
          </div>
          <div
            ref={ref}
            style={
              isExtraWidth
                ? {
                    bottom: inInView ? "18%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isLargeWidth
                ? {
                    bottom: inInView ? "18%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isMidWidth
                ? {
                    bottom: inInView ? "21%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isExtraMiniWidth
                ? {
                    bottom: inInView ? "15%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : {}
            }
            className="absolute lg:-left-10 xl:left-10 flex gap-5">
            <img
              src={message4}
              className="w-[70%] sm:w-[80%] lg:w-full"
              alt="massage"
            />
          </div>
          <div
            ref={ref}
            style={
              isExtraWidth
                ? {
                    bottom: inInView ? "10%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isLargeWidth
                ? {
                    bottom: inInView ? "10%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isMidWidth
                ? {
                    bottom: inInView ? "10%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isExtraMiniWidth
                ? {
                    bottom: inInView ? "7%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : {}
            }
            className="absolute lg:-left-10 xl:left-10 flex gap-5">
            <img
              src={message5}
              className="w-[70%] sm:w-[80%] lg:w-full"
              alt="massage"
            />
          </div>
          <div
            ref={ref}
            style={
              isExtraWidth
                ? {
                    bottom: inInView ? "3%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isLargeWidth
                ? {
                    bottom: inInView ? "3%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isMidWidth
                ? {
                    bottom: inInView ? "3%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : isExtraMiniWidth
                ? {
                    bottom: inInView ? "3%" : "-60em",
                    transition: "1.3s ease-in-out",
                  }
                : {}
            }
            className="absolute justify-end right-0 flex gap-5">
            <img
              src={message6}
              className="w-[70%] right-0 sm:w-[80%] lg:w-full"
              alt="massage"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
