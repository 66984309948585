import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./BookADemo.css";
import axios from "axios";

export const BookDemo = ({ sectionRef }) => {
  const [condition, setCondition] = useState(false);

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(null);
  const [website, setWeSite] = useState("");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  const websiteRegex =
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;

  const onNameChange = (e) => setName(e.target.value);
  const onLastNameChanged = (e) => setLastName(e.target.value);
  const onWebSiteChanged = (e) => setWeSite(e.target.value);
  const onEmailChanged = (e) => setEmail(e.target.value);

  const validWebsite = website ? websiteRegex.test(website) : true;
  const validEmail = email ? emailRegex.test(email) : true;
  const validPhone = phone ? phoneRegex.test(phone) : true;

  const CanSubmit = [name, lastName, email, phone, website].every(Boolean);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (CanSubmit) {
      setLoading(true);

      const headers = {
        "Content-Type": "application/json",
      };

      const data = {
        name: name,
        last_Name: lastName,
        email: email,
        phone_number: phone,
        website: website,
      };

      try {
        await axios.post(
          "https://us-central1-pirelly360.cloudfunctions.net/book-demo",
          data,
          { headers }
        );
        console.log("Request successful");
        setResult("request has been successfully");
      } catch (error) {
        console.log("Error:", error.message);
        setResult(error.message);
      } finally {
        setLoading(false);
      }
    }

    setInterval(() => {
      setResult("");
    }, 3000);
  };

  return (
    <section
      ref={sectionRef}
      className="Circle h-[100vh] flex text-plack overflow-hidden w-full items-center">
      <div className="max-w-7xl grid sm:grid items-center w-full py-28 sm:py-28 text-center text-white mx-auto max-h-[800px]">
        <span className="font-medium text-lg">Lets Get Started..</span>
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl my-5 font-medium">
          Join us to upgrade your dealership Visuals
        </h2>
        <div className="max-w-xl grid items-center sm:w-full pt-8 mx-auto">
          <div
            className="overflow-hidden"
            style={{
              height: condition ? "380px" : "0",
              opacity: condition ? 1 : 0,
              transition: "all ease-in-out 1.4s",
            }}>
            {loading ? (
              <div className="flex gap-16 justify-center items-center h-[300px]">
                <div className="w-6 h-6 bg-white rounded-full animate-ping"></div>
                <div className="w-6 h-6 bg-white rounded-full animate-ping"></div>
                <div className="w-6 h-6 bg-white rounded-full animate-ping"></div>
              </div>
            ) : (
              <form className="grid gap-5 mx-auto w-[350px] sm:w-full">
                <div className="flex gap-5 w-[320px] sm:w-[95%] mx-auto sm:gap-16">
                  <input
                    className="py-3 outline-none text-black font-medium px-5 w-full rounded-lg placeholder:text-sm"
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={onNameChange}
                    required
                  />
                  <input
                    className="py-3 outline-none text-black font-medium px-5 w-full rounded-lg placeholder:text-sm"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={onLastNameChanged}
                    required
                  />
                </div>
                <input
                  style={{
                    outline: validEmail ? "none" : "2px solid red",
                  }}
                  className="py-3 outline-none text-black font-medium px-5 placeholder:text-sm w-[320px] sm:w-[95%] mx-auto rounded-lg"
                  type="text"
                  placeholder="Email Address"
                  value={email}
                  onChange={onEmailChanged}
                  required
                />
                <div className="grid sm:flex gap-5 w-[320px] sm:w-[95%] mx-auto sm:gap-16">
                  <PhoneInput
                    style={{
                      outline: validPhone ? "none" : "2px solid red",
                      borderRadius: "10px",
                    }}
                    placeholder="Phone Number"
                    value={phone}
                    onChange={setPhone}
                  />
                  <input
                    style={{
                      outline: validWebsite ? "none" : "2px solid red",
                    }}
                    className="flex-1 outline-none text-black font-medium py-3 px-5 rounded-lg placeholder:text-sm"
                    type="text"
                    placeholder="Dealer Website"
                    value={website}
                    onChange={onWebSiteChanged}
                    required
                  />
                </div>
                <button
                  disabled={!CanSubmit}
                  onClick={handleSubmit}
                  className="bg-white whiteButton cursor-pointer transition-all disabled:opacity-50 duration-300 w-fit text-bluePirelly mx-auto font-semibold rounded-lg py-3 px-10">
                  Submit
                </button>
                {result ? (
                  <p className="text-white text-2xl font-semibold">{result}!</p>
                ) : (
                  ""
                )}
              </form>
            )}
          </div>
          {!condition ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                setCondition((current) => !current);
              }}
              className="bg-white whiteButton transition-all duration-300 w-fit text-bluePirelly mx-auto font-semibold rounded-lg py-3 px-10">
              Book Demo
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
};
