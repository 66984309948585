import { Player } from "@lottiefiles/react-lottie-player";
import CarAnimtion from "../../Json/car.json";
import coding from "../../Json/coding.json";
import { Link } from "react-router-dom";
import "./YouMay.css";

export default function YouMay() {
  return (
    <section className="bg-zinc-100/30 text-plack flex items-center h-fit bgYou sm:max-h-screen relative">
      <div className="bgYou"></div>
      <div className="max-w-7xl z-10 text-center py-16 mx-auto">
        <h3 className="text-bluePirelly font-semibold">Befor & After</h3>
        <h2 className="text-3xl md:text-4xl pt-5 font-bold">
          You May Also Like
        </h2>
        <div className="grid z-10 grid-cols-1 md:grid-cols-2 py-16 w-fit gap-16 mx-auto">
          <div className="shadowAi rounded-3xl p-5 w-[300px] h-[300px] grid text-center">
            <Player src={CarAnimtion} className="w-16 h-16" loop autoplay />
            <h3 className="font-bold text-2xl">AI Chatbot</h3>
            <p className="text-sm font-medium w-5/6 mx-auto">
              There are many variations passages of Lorem Ipsum available but
              the majority have suffered.
            </p>
            <Link
              to="/aichat"
              className="text-bluePirelly hover:text-blue-300 duration-200 w-fit mx-auto font-semibold text-sm">
              see more
            </Link>
          </div>
          <div className="shadowAi z-10 rounded-3xl p-5 w-[300px] h-[300px] grid text-center">
            <Player src={coding} className="w-16 h-16" loop autoplay />
            <h3 className="font-bold text-2xl">Digital Retail</h3>
            <p className="text-sm font-medium w-5/6 mx-auto">
              There are many variations passages of Lorem Ipsum available but
              the majority have suffered.
            </p>
            <button className="text-bluePirelly font-semibold text-sm">
              coming soon ..
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
