import { useInView } from "framer-motion";
import React, { useState, useEffect, useRef } from "react";

const NumberCounter = ({ number }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const step = Math.ceil(number / 100); // Increase this value for faster counting
    const animationDuration = 1000; // Adjust the duration of the animation (in milliseconds)
    const interval = Math.max(animationDuration / (number / step), 1);

    const timer = setInterval(() => {
      setCount((prevCount) => {
        const newCount = prevCount + step;
        return newCount >= number ? number : newCount;
      });
    }, interval);

    return () => clearInterval(timer);
  }, [number]);

  return <span>{count}%</span>;
};

const CustomerReview = ({ scrollToSection }) => {
  const ref = useRef(null);
  const inInView = useInView(ref, { once: true });
  return (
    <section className="bg-bluePirelly text-plack h-fit flex items-center overflow-hidden">
      <div
        ref={ref}
        className="max-w-7xl text-center w-full h-fit py-20 lg:py-28 grid gap-10 text-white mx-auto">
        <h2 className="text-lg sm:text-2xl lg:text-4xl font-bold">
          Consumer Behavior is Changing
        </h2>
        <div className="grid justify-center gap-20 text-center lg:flex w-full lg:px-4 py-10 lg:text-start lg:justify-between">
          <div className="grid sm:gap-10 lg:gap-3">
            <span className="font-semibold text-5xl sm:text-7xl">
              {inInView ? <NumberCounter number={60} /> : "0"}
            </span>
            <span className="font-medium w-80 text-sm">
              {" "}
              Increased customer engagement on VDP
            </span>
          </div>
          <div className="grid sm:gap-10 lg:gap-3">
            <span className="font-semibold text-5xl sm:text-7xl">
              {inInView ? <NumberCounter number={70} /> : "0"}
            </span>
            <span className="font-medium text-sm"> faster time to market</span>
          </div>
          <div className="grid sm:gap-10 lg:gap-3">
            <span className="font-semibold text-5xl sm:text-7xl">
              {inInView ? <NumberCounter number={100} /> : "0"}
            </span>
            <span className="font-medium lg:w-72 text-sm">
              {" "}
              Enhanced experience and more sales.
            </span>
          </div>
        </div>
        <button
          onClick={scrollToSection}
          className="bg-whitePirelly whiteButton font-semibold text-bluePirelly w-48 h-16 sm:h-20 lg:h-[80px] rounded-xl mx-auto relative">
          Book A Demo
        </button>
      </div>
    </section>
  );
};

export default CustomerReview;
