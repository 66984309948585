import { useRef } from "react";
import { useInView } from "framer-motion";

export default function Reasons() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <section>
      <div className="max-w-7xl text-plack px-4 text-center mx-auto py-16 overflow-hidden">
        <span className="text-bluePirelly font-semibold">why WeDryve?</span>
        <h2 className="md::text-4xl lg:text-4xl py-8 font-bold">
          Reasons you should have AI chat on your wesite
        </h2>
        <div className="grid grid-cols-1 w-fit mx-auto lg:mx-0 lg:w-full lg:flex gap-10 justify-center rounded-lg text-center items-center">
          <div
            ref={ref}
            style={{
              top: isInView ? "0" : "-50px",
              opacity: isInView ? "1" : "0",
              transition: "ease-in-out 1s",
            }}
            className="w-full sm:w-[410px] h-[290px] shadowDef grid justify-items-center gap-3 relative items-center p-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="73"
              height="80"
              viewBox="0 0 73 80"
              fill="none">
              <path
                opacity="0.15"
                d="M30.3559 1.56846C33.9781 -0.522818 38.4408 -0.522819 42.063 1.56845L66.5654 15.7149C70.1876 17.8062 72.4189 21.671 72.4189 25.8536V54.1465C72.4189 58.329 70.1876 62.1938 66.5654 64.2851L42.063 78.4315C38.4408 80.5228 33.9781 80.5228 30.3559 78.4315L5.85356 64.2851C2.23136 62.1938 0 58.329 0 54.1465V25.8536C0 21.671 2.23136 17.8062 5.85355 15.7149L30.3559 1.56846Z"
                fill="#FD4C5C"
              />
              <g opacity="0.9">
                <path
                  d="M39.75 36C38.505 36 37.5 34.995 37.5 33.75V26.25C37.5 25.005 38.505 24 39.75 24C40.995 24 42 25.005 42 26.25V33.75C42 34.995 40.995 36 39.75 36Z"
                  stroke="#FD4C5C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M48.75 36H46.5V33.75C46.5 32.505 47.505 31.5 48.75 31.5C49.995 31.5 51 32.505 51 33.75C51 34.995 49.995 36 48.75 36Z"
                  stroke="#FD4C5C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M32.25 42C33.495 42 34.5 43.005 34.5 44.25V51.75C34.5 52.995 33.495 54 32.25 54C31.005 54 30 52.995 30 51.75V44.25C30 43.005 31.005 42 32.25 42Z"
                  stroke="#FD4C5C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M23.25 42H25.5V44.25C25.5 45.495 24.495 46.5 23.25 46.5C22.005 46.5 21 45.495 21 44.25C21 43.005 22.005 42 23.25 42Z"
                  stroke="#FD4C5C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M39 42.75C39 41.505 40.005 40.5 41.25 40.5H48.75C49.995 40.5 51 41.505 51 42.75C51 43.995 49.995 45 48.75 45H41.25C40.005 45 39 43.995 39 42.75Z"
                  stroke="#FD4C5C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M41.25 49.5H39V51.75C39 52.995 40.005 54 41.25 54C42.495 54 43.5 52.995 43.5 51.75C43.5 50.505 42.495 49.5 41.25 49.5Z"
                  stroke="#FD4C5C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M33 35.25C33 34.005 31.995 33 30.75 33H23.25C22.005 33 21 34.005 21 35.25C21 36.495 22.005 37.5 23.25 37.5H30.75C31.995 37.5 33 36.495 33 35.25Z"
                  stroke="#FD4C5C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M30.75 28.5H33V26.25C33 25.005 31.995 24 30.75 24C29.505 24 28.5 25.005 28.5 26.25C28.5 27.495 29.505 28.5 30.75 28.5Z"
                  stroke="#FD4C5C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
            <h3 className="font-semibold">AI Intelligence</h3>
            <p className="text-sm">
              Our advanced GPT-powered chatbot understands customer inquiries,
              from the simplest to the most complex, providing accurate
              responses that mirror human interaction.
            </p>
          </div>
          <div
            ref={ref}
            style={{
              top: isInView ? "0" : "-50px",
              opacity: isInView ? "1" : "0",
              transition: "ease-in-out 2.5s",
            }}
            className="w-full sm:w-[410px] relative h-[290px] shadowDef grid justify-items-center gap-3 items-center p-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="73"
              height="80"
              viewBox="0 0 73 80"
              fill="none">
              <path
                opacity="0.15"
                d="M30.3559 1.56846C33.9781 -0.522818 38.4408 -0.522819 42.063 1.56845L66.5654 15.7149C70.1876 17.8062 72.4189 21.671 72.4189 25.8536V54.1465C72.4189 58.329 70.1876 62.1938 66.5654 64.2851L42.063 78.4315C38.4408 80.5228 33.9781 80.5228 30.3559 78.4315L5.85356 64.2851C2.23136 62.1938 0 58.329 0 54.1465V25.8536C0 21.671 2.23136 17.8062 5.85355 15.7149L30.3559 1.56846Z"
                fill="#2B59FF"
              />
              <path
                d="M36 24L21 31.5L36 39L51 31.5L36 24Z"
                stroke="#2B59FF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21 46.5L36 54L51 46.5"
                stroke="#2B59FF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21 39L36 46.5L51 39"
                stroke="#2B59FF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <h3 className="font-semibold">Instant Assistance</h3>
            <p className="text-sm">
              Whether it's day or night, our AI Chat is ready to engage
              customers 24/7, ensuring they receive immediate assistance and
              driving higher satisfaction.
            </p>
          </div>
          <div
            ref={ref}
            style={{
              top: isInView ? "0" : "-50px",
              opacity: isInView ? "1" : "0",
              transition: "ease-in-out 3s",
            }}
            className="w-full sm:w-[410px] relative h-[290px] shadowDef grid gap-3 justify-items-center items-center p-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="73"
              height="80"
              viewBox="0 0 73 80"
              fill="none">
              <path
                opacity="0.15"
                d="M30.3559 1.56846C33.9781 -0.522818 38.4408 -0.522819 42.063 1.56845L66.5654 15.7149C70.1876 17.8062 72.4189 21.671 72.4189 25.8536V54.1465C72.4189 58.329 70.1876 62.1938 66.5654 64.2851L42.063 78.4315C38.4408 80.5228 33.9781 80.5228 30.3559 78.4315L5.85356 64.2851C2.23136 62.1938 0 58.329 0 54.1465V25.8536C0 21.671 2.23136 17.8062 5.85355 15.7149L30.3559 1.56846Z"
                fill="#07A4FC"
              />
              <path
                d="M45.6667 54V50.6667C45.6667 48.8986 44.9643 47.2029 43.714 45.9526C42.4638 44.7024 40.7681 44 39 44H25.6667C23.8986 44 22.2029 44.7024 20.9526 45.9526C19.7024 47.2029 19 48.8986 19 50.6667V54"
                stroke="#07A4FC"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M32.3337 37.3333C36.0156 37.3333 39.0003 34.3486 39.0003 30.6667C39.0003 26.9848 36.0156 24 32.3337 24C28.6518 24 25.667 26.9848 25.667 30.6667C25.667 34.3486 28.6518 37.3333 32.3337 37.3333Z"
                stroke="#07A4FC"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M53.667 53.9996V50.6663C53.6659 49.1892 53.1743 47.7543 52.2693 46.5868C51.3643 45.4194 50.0972 44.5856 48.667 44.2163"
                stroke="#07A4FC"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M42 24.2163C43.434 24.5835 44.7051 25.4175 45.6127 26.5868C46.5204 27.7562 47.0131 29.1944 47.0131 30.6746C47.0131 32.1549 46.5204 33.5931 45.6127 34.7625C44.7051 35.9318 43.434 36.7658 42 37.133"
                stroke="#07A4FC"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <h3 className="font-semibold">Smart Insights</h3>
            <p className="text-sm">
              Analyze customer interactions and gain valuable insights to better
              understand preferences, concerns, and trends, empowering you to
              make data-driven decisions.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
