import { useRef } from "react";
import { useInView } from "framer-motion";

export default function SellOnline() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <section className="bg-bluePirelly Circle text-plack h-fit max-h-[100vh] mx-auto flex items-center">
      <div
        ref={ref}
        style={{
          opacity: isInView ? "1" : "0",
          transition: "ease-in-out 1s",
        }}
        className="max-w-3xl py-10 md:py-32 text-white mx-auto">
        <div className="relative text-center">
          <h2 className="text-xl sm:text-3xl lg:text-4xl font-bold py-10">
            Better Visuals = Better ROI
          </h2>
          <p className="text-sm sm:text-xl w-[80%] lg:w-full lg:text-3xl text-slate-200 leading-10 md:leading-[3.5rem] mx-auto">
            Say goodbye to static images and embrace the future of car
            exploration with our cutting-edge 360-degree turn table imaging
            technology. Our innovative solution takes car shopping to a whole
            new level by allowing customers to interactively experience every
            angle of the vehicle, right from their screens.
          </p>
        </div>
      </div>
    </section>
  );
}
