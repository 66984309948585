import Virtual from "./Virtual";
import Header from "./Header";
import PirellyProducts from "./PirellyProducts";
import IntelligentChat from "./IntelligentChat";
import DigitalRetail from "./DigitalRetail";
import CustomerReview from "./CustomerReview";
// import VideoSection from "./VideoSection";
import Questions from "./Questions";
import { BookDemo } from "./BookDemo";
import Footer from "./Footer";
import Leaders from "./Leaders";

export default function HomePage({ scrollToSection, sectionRef }) {
  return (
    <>
      <Header />
      <PirellyProducts />
      <Virtual />
      <IntelligentChat />
      <DigitalRetail />
      <CustomerReview scrollToSection={scrollToSection} />
      <Leaders />
      {/* <Comments /> */}
      {/* <VideoSection /> */}
      <Questions />
      <BookDemo sectionRef={sectionRef} />
      <Footer />
    </>
  );
}
