import Phones from "../../assets/phones.png";
import background from "../../assets/back-for-dis.webp";
import user from "../../assets/avatar-next-page2.webp";
import { useRef, useState } from "react";
import { useInView } from "framer-motion";
import "./UniqeExprince.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useMediaQuery } from "react-responsive";

export default function UniqeExprince() {
  const [isPublish, setIsPublish] = useState(true);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const isExtraMiniWidth = useMediaQuery({ query: "(min-width: 280px)" });
  const isMiniWidth = useMediaQuery({ query: "(min-width: 500px)" });
  const isMidWidth = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <section className="h-fit flex p-5 text-plack sm:p-20 items-center max-w-[1520px] mx-auto overflow-hidden">
      <div className="max-w-7xl w-full py-20 grid gap-10 lg:gap-0 lg:flex mx-auto">
        <div className="relative w-full text-center  lg:text-start lg:w-1/3">
          <span className="text-bluePirelly font-semibold text-sm">
            Manage your projects in the app
          </span>
          <h2 className="font-bold py-5 text-2xl lg:text-3xl xl:text-4xl">
            An Exceptionally unique experience tailored to you
          </h2>
          <p className="hidden text-slate-500 pb-10 text-sm lg:block">
            Say goodbye to static images and embrace the future of car
            exploration with our cutting-edge 360-degree turn table imaging
            technology. Our innovative solution takes car shopping to a whole
            new level by allowing customers to interactively experience every
            angle of the vehicle, right from their screens.
          </p>
        </div>
        <div className="relative w-full lg:w-2/3">
          <img
            className="absolute hidden lg:block right-16 -z-40 top-0"
            src={background}
            alt="background"
          />
          <div className="w-[100%] md:w-[70%] xl:w-[65%] mx-auto">
            <LazyLoadImage
              effect="blur"
              width={"100%"}
              className="relative -top-10 -right-5"
              src={Phones}
              alt="frame"
            />
            <div
              ref={ref}
              style={
                isMidWidth
                  ? {
                      width: isInView ? "11rem" : "0",
                      transition: "ease-out 1s",
                    }
                  : isMiniWidth
                  ? {
                      width: isInView ? "10rem" : "0",
                      transition: "ease-out 1s",
                    }
                  : isExtraMiniWidth
                  ? {
                      width: isInView ? "9rem" : "0",
                      transition: "ease-out 1s",
                    }
                  : {}
              }
              className="absolute overflow-hidden top-56 
              lg:top-0 xl:left-16 left-5 flex h-8 sm:h-12 justify-center rounded-md p-2 bg-bluePirelly gap-2 items-center">
              <svg
                className="bg-white p-1 w-5 h-5  rounded-full"
                xmlns="http://www.w3.org/2000/svg"
                width={"10"}
                height="7"
                viewBox="0 0 10 7"
                fill="white">
                <path
                  d="M9.40396 0.20257C9.34029 0.138382 9.26455 0.0874352 9.1811 0.0526676C9.09765 0.0178999 9.00814 0 8.91773 0C8.82733 0 8.73782 0.0178999 8.65436 0.0526676C8.57091 0.0874352 8.49517 0.138382 8.43151 0.20257L3.32956 5.31136L1.18606 3.16101C1.11996 3.09716 1.04193 3.04695 0.956429 3.01326C0.870925 2.97956 0.779622 2.96304 0.687731 2.96463C0.595841 2.96622 0.505164 2.98589 0.420877 3.02252C0.33659 3.05916 0.260343 3.11203 0.196491 3.17813C0.132639 3.24423 0.0824322 3.32226 0.0487361 3.40777C0.0150401 3.49327 -0.00148507 3.58457 0.000104722 3.67646C0.00169452 3.76835 0.021368 3.85903 0.0580017 3.94332C0.0946353 4.02761 0.147512 4.10385 0.213612 4.1677L2.84334 6.79743C2.907 6.86162 2.98274 6.91257 3.0662 6.94733C3.14965 6.9821 3.23916 7 3.32956 7C3.41997 7 3.50948 6.9821 3.59293 6.94733C3.67638 6.91257 3.75213 6.86162 3.81579 6.79743L9.40396 1.20926C9.47347 1.14513 9.52895 1.0673 9.56689 0.980672C9.60483 0.894042 9.62442 0.800492 9.62442 0.705916C9.62442 0.611341 9.60483 0.51779 9.56689 0.43116C9.52895 0.34453 9.47347 0.266699 9.40396 0.20257Z"
                  fill="#07A4FC"
                />
              </svg>
              <h4 className="text-white text-[10px] sm:text-sm">
                manage project{" "}
              </h4>
            </div>
            <div
              ref={ref}
              style={
                isMidWidth
                  ? {
                      width: isInView ? "11rem" : "0",
                      transition: "ease-out 1s",
                    }
                  : isMiniWidth
                  ? {
                      width: isInView ? "11rem" : "0",
                      transition: "ease-out 1s",
                    }
                  : isExtraMiniWidth
                  ? {
                      width: isInView ? "9rem" : "0",
                      transition: "ease-out 1s",
                    }
                  : {}
              }
              className="absolute overflow-hidden right-0 sm:-right-16 -top-10 sm:top-0 flex w-44 h-10 sm:h-12 justify-center rounded-md p-2 bg-bluePirelly gap-2 xl:right-5 items-center">
              <img src={user} alt="userphoto" className="w-8 sm:w-12" />
              <h4 className="text-white text-[10px] sm:text-sm">
                Create project
              </h4>
            </div>
            <div
              ref={ref}
              style={
                isMidWidth
                  ? {
                      width: isInView ? "10rem" : "0",
                      transition: "ease-out 1s",
                    }
                  : isMiniWidth
                  ? {
                      width: isInView ? "9rem" : "0",
                      transition: "ease-out 1s",
                    }
                  : isExtraMiniWidth
                  ? {
                      width: isInView ? "8rem" : "0",
                      transition: "ease-out 1s",
                    }
                  : {
                      width: isInView ? "11rem" : "0",
                      transition: "ease-out 1s",
                    }
              }
              className="absolute transition-all duration-200 bg-white overflow-hidden -top-10 sm:top-0 lg:top-96 flex rounded-md justify-center shadowDef p-2 w-44 h-10 sm:h-12 gap-2 items-center">
              {!isPublish ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none">
                  <path
                    d="M9.99903 5.33437H3.33301V4.66777C3.3328 3.92878 3.5782 3.21067 4.03062 2.62635C4.48304 2.04203 5.11682 1.62464 5.83232 1.43979C6.54782 1.25494 7.30446 1.31313 7.98329 1.60519C8.66212 1.89726 9.22464 2.40664 9.5824 3.05326C9.6249 3.12981 9.68206 3.19725 9.75062 3.25171C9.81918 3.30617 9.8978 3.34659 9.98199 3.37067C10.0662 3.39475 10.1543 3.40201 10.2413 3.39204C10.3282 3.38208 10.4124 3.35507 10.489 3.31257C10.5655 3.27007 10.633 3.21291 10.6874 3.14435C10.7419 3.07578 10.7823 2.99717 10.8064 2.91298C10.8305 2.8288 10.8377 2.7407 10.8278 2.65371C10.8178 2.56672 10.7908 2.48254 10.7483 2.40599C10.2472 1.50096 9.45956 0.788094 8.50919 0.379463C7.55883 -0.0291673 6.49961 -0.110398 5.49804 0.148541C4.49648 0.407479 3.60936 0.991904 2.97613 1.80995C2.3429 2.62799 1.99946 3.63328 1.99981 4.66777V5.61701C1.4061 5.87612 0.900774 6.30263 0.545613 6.84437C0.190452 7.38611 0.000852167 8.0196 0 8.66738V12.667C0.00105847 13.5506 0.352554 14.3978 0.977385 15.0226C1.60222 15.6474 2.44936 15.9989 3.33301 16H9.99903C10.8827 15.9989 11.7298 15.6474 12.3547 15.0226C12.9795 14.3978 13.331 13.5506 13.332 12.667V8.66738C13.331 7.78374 12.9795 6.93659 12.3547 6.31176C11.7298 5.68693 10.8827 5.33543 9.99903 5.33437ZM11.9988 12.667C11.9988 13.1974 11.7881 13.706 11.4131 14.0811C11.0381 14.4561 10.5294 14.6668 9.99903 14.6668H3.33301C2.80263 14.6668 2.29397 14.4561 1.91893 14.0811C1.5439 13.706 1.3332 13.1974 1.3332 12.667V8.66738C1.3332 8.137 1.5439 7.62834 1.91893 7.2533C2.29397 6.87827 2.80263 6.66758 3.33301 6.66758H9.99903C10.5294 6.66758 11.0381 6.87827 11.4131 7.2533C11.7881 7.62834 11.9988 8.137 11.9988 8.66738V12.667Z"
                    fill="#109865"
                  />
                  <path
                    d="M6.66599 9.33252C6.4892 9.33252 6.31964 9.40275 6.19463 9.52776C6.06962 9.65277 5.99939 9.82233 5.99939 9.99912V11.3323C5.99939 11.5091 6.06962 11.6787 6.19463 11.8037C6.31964 11.9287 6.4892 11.9989 6.66599 11.9989C6.84279 11.9989 7.01234 11.9287 7.13735 11.8037C7.26236 11.6787 7.33259 11.5091 7.33259 11.3323V9.99912C7.33259 9.82233 7.26236 9.65277 7.13735 9.52776C7.01234 9.40275 6.84279 9.33252 6.66599 9.33252Z"
                    fill="#109865"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none">
                  <path
                    d="M11.05 5.4756V4.55C11.05 3.34327 10.5706 2.18595 9.71734 1.33266C8.86405 0.479374 7.70673 0 6.5 0C5.29326 0 4.13595 0.479374 3.28266 1.33266C2.42937 2.18595 1.95 3.34327 1.95 4.55V5.4756C1.37108 5.72826 0.87834 6.14414 0.532024 6.67239C0.185708 7.20064 0.000830944 7.81835 0 8.45V12.35C0.00103211 13.2116 0.343773 14.0377 0.953043 14.647C1.56231 15.2562 2.38836 15.599 3.25 15.6H9.75C10.6116 15.599 11.4377 15.2562 12.047 14.647C12.6562 14.0377 12.999 13.2116 13 12.35V8.45C12.9992 7.81835 12.8143 7.20064 12.468 6.67239C12.1217 6.14414 11.6289 5.72826 11.05 5.4756ZM3.25 4.55C3.25 3.68805 3.59241 2.8614 4.2019 2.2519C4.8114 1.64241 5.63805 1.3 6.5 1.3C7.36195 1.3 8.1886 1.64241 8.7981 2.2519C9.40759 2.8614 9.75 3.68805 9.75 4.55V5.2H3.25V4.55ZM11.7 12.35C11.7 12.8672 11.4946 13.3632 11.1289 13.7289C10.7632 14.0946 10.2672 14.3 9.75 14.3H3.25C2.73283 14.3 2.23684 14.0946 1.87114 13.7289C1.50545 13.3632 1.3 12.8672 1.3 12.35V8.45C1.3 7.93283 1.50545 7.43684 1.87114 7.07114C2.23684 6.70545 2.73283 6.5 3.25 6.5H9.75C10.2672 6.5 10.7632 6.70545 11.1289 7.07114C11.4946 7.43684 11.7 7.93283 11.7 8.45V12.35Z"
                    fill="#E00F0F"
                  />
                  <path
                    d="M6.5001 9.1001C6.32771 9.1001 6.16238 9.16858 6.04048 9.29048C5.91858 9.41238 5.8501 9.57771 5.8501 9.7501V11.0501C5.8501 11.2225 5.91858 11.3878 6.04048 11.5097C6.16238 11.6316 6.32771 11.7001 6.5001 11.7001C6.67249 11.7001 6.83782 11.6316 6.95972 11.5097C7.08162 11.3878 7.1501 11.2225 7.1501 11.0501V9.7501C7.1501 9.57771 7.08162 9.41238 6.95972 9.29048C6.83782 9.16858 6.67249 9.1001 6.5001 9.1001Z"
                    fill="#E00F0F"
                  />
                </svg>
              )}
              <h4 className="w-16 mx-auto">
                {isPublish ? "Publish" : "Unpulish"}
              </h4>
              <div class="checkbox-wrapper-3">
                <input
                  onClick={() => setIsPublish((current) => !current)}
                  type="checkbox"
                  id="cbx-3"
                />
                <label for="cbx-3" class="toggle">
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <p className="text-slate-500 block lg:hidden text-center pb-10 text-sm">
          Say goodbye to static images and embrace the future of car exploration
          with our cutting-edge 360-degree turn table imaging technology. Our
          innovative solution takes car shopping to a whole new level by
          allowing customers to interactively experience every angle of the
          vehicle, right from their screens.
        </p>
      </div>
    </section>
  );
}
