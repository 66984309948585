import logo from "../../assets/logo (1).webp";
import logo2 from "../../assets/logo (2).webp";
import logo3 from "../../assets/logo (3).webp";
import logo4 from "../../assets/logo (4).webp";
import logo5 from "../../assets/logo (5).webp";
import logo6 from "../../assets/logo (6).webp";
import logo7 from "../../assets/logo (7).webp";

export default function Leaders() {
  return (
    <section>
      <div className="max-w-7xl text-plack grid items-center mx-auto py-16 text-center overflow-auto  justify-center">
        <h2 className="text-3xl sm:text-4xl py-8 font-bold">
          Trusted by Industry Leaders
        </h2>
        <p>We have partnered up with all major DMS systems in the market.</p>
        <div className="flex py-10 justify-between w-[95%] gap-2 sm:gap-10 mx-auto items-center">
          <div className="shadowLogo px-4 py-2 h-full sm:h-16 flex items-center rounded-lg">
            <img src={logo} alt="logo" />
          </div>
          <div className="shadowLogo px-4 py-2 h-10 sm:h-16 flex items-center rounded-lg">
            <img src={logo2} alt="logo" />
          </div>
          <div className="shadowLogo px-4 py-2 h-10 sm:h-16 flex items-center rounded-lg">
            <img src={logo3} alt="logo" />
          </div>
          <div className="shadowLogo px-4 py-2 h-10 sm:h-16 flex items-center rounded-lg">
            <img src={logo4} alt="logo" />
          </div>
        </div>
        <div className="flex justify-between w-4/5 gap-2 sm:gap-10 mx-auto items-center">
          <div className="shadowLogo px-4 py-2 h-10 sm:h-16 flex items-center rounded-lg">
            <img src={logo5} alt="logo" />
          </div>
          <div className="shadowLogo px-4 py-2 h-10 sm:h-16 flex items-center rounded-lg">
            <img src={logo6} alt="logo" />
          </div>
          <div className="shadowLogo px-4 py-2 h-10 sm:h-16 flex items-center rounded-lg">
            <img src={logo7} alt="logo" />
          </div>
        </div>
      </div>
    </section>
  );
}
