import bg from "../../assets/bg-about.webp";
import photo from "../../assets/photo-contact.webp";
// import "./AboutUs.css";
import Footer from "../homePage/Footer";
// import person1 from "../../assets/person11.webp";
// import person2 from "../../assets/person2.webp";
// import person3 from "../../assets/person3.webp";
// import person4 from "../../assets/person4.webp";
import { useRef } from "react";
import { useInView } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function AboutUs() {
  const ref = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const isInView = useInView(ref, { once: true });
  const isInView2 = useInView(ref2, { once: true });
  const isInView3 = useInView(ref3, { once: true });

  return (
    <section ref={ref} className="text-plack overflow-hidden">
      <div>
        <div className="grid relative lg:flex lg:py-28 items-center max-w-7xl gap-32 mx-auto">
          <div
            style={{
              opacity: isInView ? "1" : "0",
              transition: "ease-in-out 1.5s",
            }}
            className="flex-1 overflow-hidden lg:w-1/2">
            <div className="w-full hidden lg:block">
              <img
                className="w-[70%] left-10 relative"
                src={bg}
                alt="background"
              />
            </div>
            <div className="relative lg:absolute lg:top-52 lg:left-36 lg:w-[35%]">
              <LazyLoadImage
                effect="blur"
                width={"100%"}
                src={photo}
                alt="pesonlphoto"
              />
              <div className="lg:hidden bg-gradient-to-b w-full from-white/0 to-black py-5 text-center grid gap-3 absolute bottom-0 text-white">
                <h2 className="text-4xl font-semibold py-10">About</h2>
              </div>
            </div>
            <p className="w-[80%] mx-auto lg:hidden text-sm sm:text-base py-10 text-center">
              At WeDryve, we are at the forefront of innovation in the
              automotive industry. With a deep-rooted passion for AI technology,
              we're transforming car dealerships into dynamic hubs of customer
              engagement and seamless transactions. Backed by a team of AI
              enthusiasts and automotive experts, we're dedicated to empowering
              dealerships to thrive in the digital age. Join us in reshaping the
              future of automotive retail.
            </p>
          </div>
          <div className="flex-1 hidden lg:block">
            <h2 className="text-5xl font-bold">About WeDryve</h2>
            <p className="font-medium hidden lg:block text-slate-500 py-12 w-[400px]">
              At WeDryve, we are at the forefront of innovation in the
              automotive industry. With a deep-rooted passion for AI technology,
              we're transforming car dealerships into dynamic hubs of customer
              engagement and seamless transactions. Backed by a team of AI
              enthusiasts and automotive experts, we're dedicated to empowering
              dealerships to thrive in the digital age. Join us in reshaping the
              future of automotive retail.
            </p>
          </div>
        </div>
        <div className="bg-bluePirelly/10 mx-auto">
          <div className="grid lg:flex px-4 max-w-7xl mx-auto items-center py-10 lg:py-24">
            <div className="w-full py-10 lg:w-1/2">
              <p className="text-black leading-snug text-xl sm:text-3xl text-center lg:text-start lg:text-4xl font-bold block">
                We are a small team with a big dream! To bring trust,
                transparency and simplicity back to car marketplace.
              </p>
            </div>
            <div className="grid w-full lg:w-1/2 justify-center lg:justify-end gap-5 relative">
              <div
                ref={ref2}
                style={{
                  opacity: isInView2 ? "1" : "0",
                  transition: "ease-in-out 1.5s",
                }}
                className="flex gap-8 h-[90px]">
                <div className="text-white bg-bluePirelly px-5 border-8 border-skybg h-[60px]  flex items-center relative rounded-full">
                  1
                  <div className="bg-bluePirelly/30 h-full top-14 w-0.5 -translate-x-1/2 left-1/2 absolute"></div>
                </div>
                <div
                  ref={ref}
                  style={{
                    opacity: isInView ? "1" : "0",
                    transition: "ease-in-out 1s",
                  }}
                  className="flex-1">
                  <h4 className="font-semibold">Innovation First</h4>
                  <p className="w-[90%] sm:w-[300px] text-slate-500 text-sm">
                    We lead with pioneering ideas, reshaping the automotive
                    landscape through cutting-edge AI solutions.
                  </p>
                </div>
              </div>
              <div
                ref={ref2}
                style={{
                  opacity: isInView2 ? "1" : "0",
                  transition: "ease-in-out 2s",
                }}
                className="flex gap-8 h-[90px]">
                <div className="text-white bg-bluePirelly px-5 border-8 border-skybg h-16  flex items-center relative rounded-full">
                  2
                  <div className="bg-bluePirelly/30 h-full top-14 w-0.5 -translate-x-1/2 left-1/2 absolute"></div>
                </div>
                <div className="flex-1 gap-8">
                  <h4 className="font-semibold">Customer Obsession</h4>
                  <p className="w-[90%] sm:w-[300px] text-slate-500 text-sm">
                    Our customers' satisfaction is paramount we're dedicated to
                    crafting exceptional experiences.
                  </p>
                </div>
              </div>
              <div
                ref={ref2}
                style={{
                  opacity: isInView2 ? "1" : "0",
                  transition: "ease-in-out 2.5s",
                }}
                className="flex gap-8 h-[90px]">
                <div className="text-white bg-bluePirelly px-5 border-8 border-skybg h-16 flex items-cente items-center relative rounded-full">
                  3
                  <div className="bg-bluePirelly/30 h-full top-14 w-0.5 -translate-x-1/2 left-1/2 absolute"></div>
                </div>
                <div className="flex-1">
                  <h4 className="font-semibold">Transparency Always</h4>
                  <p className="w-[90%] sm:w-[300px] text-slate-500 text-sm">
                    We build trust by openly sharing information, fostering
                    strong relationships with integrity.
                  </p>
                </div>
              </div>
              <div
                ref={ref2}
                style={{
                  opacity: isInView2 ? "1" : "0",
                  transition: "ease-in-out 3s",
                }}
                className="flex items-center gap-8 h-[90px]">
                <div className="text-white bg-bluePirelly px-5 border-8 border-skybg h-16  flex items-center relative rounded-full">
                  4
                </div>
                <div className="flex-1">
                  <h4 className="font-semibold">Empowerment Together</h4>
                  <p className="w-[90%] sm:w-[300px] text-slate-500 text-sm">
                    We empower dealerships with the tools they need to succeed,
                    fostering growth and success collaboratively.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* team section */}
        {/* <div className="max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-4xl font-bold py-10">Our qualified team</h2>
            <p className="w-[28rem] mx-auto text-slate-600">
              Fully layered dolor sit amet, consectetur Facere, nobis, id
              expedita dolores officiis laboriosam.
            </p>
            <div className="flex items-center gap-5 justify-center mx-auto py-20">
              <div
                ref={ref3}
                style={{
                  opacity: isInView3 ? "1" : "0",
                  left: isInView3 ? "17rem" : "30rem",
                  transition: "ease-out 1s",
                }}
                className="w-[300px]  transition-all duration-200 cursor-pointer hover:shadow-2xl rounded-md p-4 h-[380px] text-start">
                <LazyLoadImage effect="blur" src={person1} alt="person" />
                <div className="py-5">
                  <h3 className="font-semibold text-lg">Cameron Williamson</h3>
                  <span className="text-slate-500 text-sm">Co -Founder</span>
                </div>
              </div>
              <div
                style={{
                  opacity: isInView3 ? "1" : "0",
                  left: isInView3 ? "17rem" : "30rem",
                  transition: "ease-out 1.8s",
                }}
                className="w-[300px] transition-all duration-200 cursor-pointer hover:shadow-2xl rounded-md p-4 h-[380px] text-start">
                <LazyLoadImage effect="blur" src={person2} alt="person" />
                <div className="py-5">
                  <h3 className="font-semibold text-lg">Brooklyn Simmons</h3>
                  <span className="text-slate-500 text-sm">
                    Project Manager
                  </span>
                </div>
              </div>
              <div
                style={{
                  opacity: isInView3 ? "1" : "0",
                  left: isInView3 ? "17rem" : "30rem",
                  transition: "ease-out 2.8s",
                }}
                className="w-[300px] transition-all duration-200 cursor-pointer hover:shadow-2xl rounded-md p-4 h-[380px] text-start">
                <LazyLoadImage effect="blur" src={person3} alt="person" />
                <div className="py-5">
                  <h3 className="font-semibold text-lg">Robert Fox</h3>
                  <span className="text-slate-500 text-sm">Web Designer</span>
                </div>
              </div>
              <div
                ref={ref}
                style={{
                  opacity: isInView3 ? "1" : "0",
                  left: isInView3 ? "17rem" : "30rem",
                  transition: "ease-out 3s",
                }}
                className="w-[300px] transition-all duration-200 cursor-pointer hover:shadow-2xl rounded-md p-4 h-[380px] text-start">
                <LazyLoadImage effect="blur" src={person4} alt="person" />
                <div className="py-5">
                  <h3 className="font-semibold text-lg">Esther Howard</h3>
                  <span className="text-slate-500 text-sm">HR Manager</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="bg-bluePirelly text-white">
          <div className="CircleBg py-28 grid text-center gap-10">
            <h2 className="font-bold text-4xl">join to WeDryve team</h2>
            <span className="block font-medium opacity-80">description..</span>
            <button className="px-14 py-3 bg-white text-bluePirelly w-52 mx-auto font-semibold rounded-xl">
              More Info
            </button>
          </div>
        </div> */}
        <div
          ref={ref3}
          style={{
            opacity: isInView3 ? "1" : "0",
            top: isInView3 ? "0" : "-100px",
            transition: "ease-in-out 1.5s",
          }}
          className="flex-1 grid lg:flex items-center text-center relative rounded-lg py-16">
          <div className="w-full lg:w-1/2">
            <h2 className="text-3xl py-10 font-bold lg:text-4xl">
              Have questions? <br /> Shoot us an email
            </h2>
            <p className="w-[80%] py-4 mx-auto">
              Have questions about our products, features, free trials, or
              pricing?
            </p>
          </div>
          <form className="grid sm:justify-center p-5 sm:p-10 gap-10 items-center w-full lg:w-1/2">
            <input
              className="bg-zinc-100 rounded-md h-[55px] w-full sm:w-[550px] placeholder:text-black px-6 py-2"
              type="text"
              placeholder="Enter your Name*"
            />
            <input
              className="bg-zinc-100 rounded-md h-[55px] w-full sm:w-[550px] placeholder:text-black px-6 py-2"
              type="email"
              placeholder="Enter your email*"
            />
            <textarea
              className="bg-zinc-100 rounded-md h-[150px] w-full sm:w-[550px] placeholder:text-black px-6 py-2"
              type="text"
              placeholder="Enter your message*"
            />
            <button className="px-5 blueButton  py-4 bg-bluePirelly w-44 text-white lg:w-full rounded-lg font-medium">
              Send Message
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </section>
  );
}
