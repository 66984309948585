import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import L from "leaflet";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { useMediaQuery } from "react-responsive";

// change leaflet icon
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

export default function MapLocation() {
  const isMiniExtra = useMediaQuery({ query: "(min-width: 280px)" });
  const isMidWidth = useMediaQuery({ query: "(min-width: 768px)" });
  return (
    <>
      <MapContainer
        center={[37.318661140736666, -121.94735190501359]}
        zoom={10}
        style={{
          width: isMidWidth ? "800px" : isMiniExtra ? "380px" : "380px",
          height: isMidWidth ? "500px" : isMiniExtra ? "250px" : "250px",
          overflow: "hidden",
        }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[37.318661140736666, -121.94735190501359]}>
          <Popup>A marker with a popup.</Popup>
        </Marker>
      </MapContainer>
    </>
  );
}
