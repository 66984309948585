import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import chatbg from "../../assets/iphoneframe.png";
import chat1 from "../../assets/m1.png";
import chat2 from "../../assets/m2.png";
import chat3 from "../../assets/m3.png";
import chat4 from "../../assets/m4.png";
import { useInView } from "framer-motion";
import { useRef } from "react";
import { useMediaQuery } from "react-responsive";

export default function IntelligentChat() {
  const ref = useRef(null);
  const inInView = useInView(ref, { once: true });

  const isExtraMiniWidth = useMediaQuery({ query: "(min-width: 240px)" });
  const isMiniWidth = useMediaQuery({ query: "(min-width: 640px)" });
  const isMidWidth = useMediaQuery({ query: "(min-width: 768px)" });
  const isLargeWidth = useMediaQuery({ query: "(min-width: 1024px)" });
  const isExtraWidth = useMediaQuery({ query: "(min-width: 1280px)" });

  return (
    <section className="overflow-hidden text-plack">
      <div className="max-w-7xl py-16 sm:py-0 md:px-4 sm:block md:block lg:flex lg:px-10 lg:gap-16 xl:gap-28 mx-auto">
        <div className="sm:w-full sm:text-center text-center md:w-full md:text-center lg:text-start lg:w-1/2 grid  lg:h-[500px]">
          <div>
            <span className="text-bluePirelly font-semibold text-lg">
              We Made It Smart
            </span>
            <h2 className="text-3xl sm:text-5xl mt-3 font-bold">
              Intelligent Chat 2.0
            </h2>
          </div>
          <p className="font-normal w-[80%] mx-auto lg:mx-0  sm:w-[80%] sm:mx-auto md:w-[80%] md:mx-auto py-0 lg:w-[550px]">
            Our AI-powered chatbot revolutionizes customer interactions by
            providing intelligent, automated assistance. With natural language
            processing and machine learning capabilities, it offers personalized
            responses, instant support, and seamless engagement, delivering a
            superior and efficient customer service experience.
          </p>
          <p className="hidden lg:flex gap-5 lg:justify-start text-xs font-medium w-full">
            <span>
              <FontAwesomeIcon
                className="text-bluePirelly pr-2"
                icon={faCheck}
              />
              Powered by CHAT GPT
            </span>
            <span>
              <FontAwesomeIcon
                className="text-bluePirelly pr-2"
                icon={faCheck}
              />
              99% Response rate
            </span>
            <span>
              <FontAwesomeIcon
                className="text-bluePirelly pr-2"
                icon={faCheck}
              />
              Faster response time = More Sale
            </span>
          </p>
          <button className="blueButton hidden lg:block bg-bluePirelly w-fit px-6 text-white h-14 rounded-xl">
            Learn More ..
          </button>
        </div>
        <div
          ref={ref}
          className="w-[300px] pt-5 mx-auto sm:w-[350px] sm:mx-auto lg:w-fit relative">
          <img
            src={chatbg}
            className="w-[280px] sm:mx-auto lg:mx-0"
            alt="display of chat Ai"
          />
          <img
            style={
              isExtraWidth
                ? {
                    left: inInView ? "15%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isLargeWidth
                ? {
                    left: inInView ? "12%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isMidWidth
                ? {
                    left: inInView ? "20%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isMiniWidth
                ? {
                    left: inInView ? "20%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isExtraMiniWidth
                ? {
                    left: inInView ? "15%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : {}
            }
            src={chat4}
            className="absolute w-[40%] top-[16.3rem] sm:w-[40%] lg:w-3/6 left-32 sm:top-[17rem]"
            alt="display of chat Ai"
          />
          <img
            style={
              isExtraWidth
                ? {
                    left: inInView ? "35%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isLargeWidth
                ? {
                    left: inInView ? "40%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isMidWidth
                ? {
                    left: inInView ? "40%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isMiniWidth
                ? {
                    left: inInView ? "40%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isExtraMiniWidth
                ? {
                    left: inInView ? "43%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : {}
            }
            src={chat2}
            className="absolute w-[40%] sm:w-[40%] md:w-[40%] lg:w-3/6 lg:left-10 top-[19rem]"
            alt="display of chat Ai"
          />
          <img
            style={
              isExtraWidth
                ? {
                    left: inInView ? "19%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isLargeWidth
                ? {
                    left: inInView ? "24%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isMidWidth
                ? {
                    left: inInView ? "30%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isMiniWidth
                ? {
                    left: inInView ? "30%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isExtraMiniWidth
                ? {
                    left: inInView ? "33%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : {}
            }
            src={chat3}
            className="absolute w-[50%] md:w-[50%] lg:w-4/6 xl:w-4/6 left-10 top-[14rem]"
            alt="display of chat Ai"
          />
          <img
            style={
              isExtraWidth
                ? {
                    left: inInView ? "35%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isLargeWidth
                ? {
                    left: inInView ? "40%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isMidWidth
                ? {
                    left: inInView ? "40%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isMiniWidth
                ? {
                    left: inInView ? "40%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isExtraMiniWidth
                ? {
                    left: inInView ? "43%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : {}
            }
            src={chat2}
            className="absolute w-[40%] sm:w-[40%] lg:w-3/6 left-32 top-48"
            alt="display of chat Ai"
          />
          <img
            style={
              isExtraWidth
                ? {
                    left: inInView ? "15%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isLargeWidth
                ? {
                    left: inInView ? "2.5rem" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isMidWidth
                ? {
                    left: inInView ? "20%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isMiniWidth
                ? {
                    left: inInView ? "20%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isExtraMiniWidth
                ? {
                    left: inInView ? "13.5%" : "-15rem",
                    transition: "ease-in-out 1.5s",
                  }
                : {}
            }
            src={chat1}
            className="absolute w-[45%] sm:w-[45%] md:w-[40%] lg:w-3/6 xl:w-3/6 left-10 top-[9.5rem]"
            alt="display of chat Ai"
          />
          <div className="grid pt-5 lg:hidden">
            <p className="grid text-center gap-5 justify-center text-xs font-medium w-full">
              <span>
                <FontAwesomeIcon
                  className="text-bluePirelly pr-2"
                  icon={faCheck}
                />
                Powered by CHAT GPT
              </span>
              <span>
                <FontAwesomeIcon
                  className="text-bluePirelly pr-2"
                  icon={faCheck}
                />
                99% Response rate
              </span>
              <span>
                <FontAwesomeIcon
                  className="text-bluePirelly pr-2"
                  icon={faCheck}
                />
                Faster response time = More Sale
              </span>
            </p>
            <button className="bg-bluePirelly w-fit px-6 text-white h-14 rounded-xl mx-auto mt-10">
              Learn More ..
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
