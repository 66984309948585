import dashboard1 from "../../assets/Dashborad 1.webp";
import dashboard2 from "../../assets/Dashborad 2.png";
import { useRef } from "react";
import { useInView } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useMediaQuery } from "react-responsive";

export default function Dashboard() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const isExtraMiniWidth = useMediaQuery({ query: "(min-width: 280px)" });
  const isMidWidth = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <section className="h-fit max-h-[100vh] text-plack max-w-[1520px] mx-auto flex items-center overflow-hidden">
      <div
        ref={ref}
        className="max-w-7xl md:grid md:gap-10 lg:gap-0 lg:flex gap-20 mx-auto">
        <div className="py-14 relative w-fit mx-auto lg:mx-auto lg:w-1/2 xl:w-2/3">
          <div
            style={
              isMidWidth
                ? {
                    left: isInView ? "20%" : "10rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isExtraMiniWidth
                ? {
                    left: isInView ? "20%" : "10rem",
                    transition: "ease-in-out 1.5s",
                  }
                : {
                    left: isInView ? "0" : "10rem",
                    transition: "ease-in-out 1.5s",
                  }
            }
            className="absolute left-20 shadowDef w-[75%] lg:w-[80%] xl:w-[600px] top-24">
            <LazyLoadImage effect="blur" src={dashboard1} alt="edittools" />
          </div>
          <div
            style={
              isMidWidth
                ? {
                    left: isInView ? "3%" : "10rem",
                    transition: "ease-in-out 1.5s",
                  }
                : isExtraMiniWidth
                ? {
                    left: isInView ? "10%" : "10rem",
                    transition: "ease-in-out 1.5s",
                  }
                : {
                    left: isInView ? "0" : "10rem",
                    transition: "ease-in-out 1.5s",
                  }
            }
            className="relative top-5 lg:top-0 w-[65%] lg:w-[75%] xl:w-[600px] shadowDef">
            <LazyLoadImage effect="blur" src={dashboard2} alt="edittools" />
          </div>
        </div>
        <div className="relative px-4 py-20 w-full text-center lg:text-start lg:w-1/2 xl:w-1/3">
          <span className="text-bluePirelly font-semibold text-sm">
            You have all the possibilities to edit your project
          </span>
          <h2 className="font-bold py-5 text-2xl lg:text-3xl xl:text-4xl">
            Advanced Dashboard Editor
          </h2>
          <p className="font-normal w-[90%] mx-auto pb-16 md:w-[80%] md:mx-auto lg:mx-0 lg:w-full">
            Your Ultimate Vehicle Showcase Control Center. With a user-friendly
            interface and powerful features, our dashboard empowers dealerships
            to effortlessly manage and enhance their vehicle presentation like
            never before. Seamlessly upload high-resolution images, create
            captivating 360-degree views, and customize every aspect of your
            virtual showroom.
          </p>
        </div>
      </div>
    </section>
  );
}
