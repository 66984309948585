import { faCheck, faCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import carF from "../../assets/front.webp";
import carL from "../../assets/left.webp";
import carR from "../../assets/right.webp";
import carB from "../../assets/back.webp";
import back from "../../assets/backww.webp";
import circle from "../../assets/wwCircle.webp";
import wheel from "../../assets/Rectangle 271.webp";
import { useInView } from "framer-motion";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectFade, Navigation, Pagination } from "swiper/modules";
import "./Virtual.css";
import { useMediaQuery } from "react-responsive";

function Virtual() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const [show, setShow] = useState(true);

  const isMiniWidth = useMediaQuery({ query: "(min-width: 640px)" });
  const isMidWidth = useMediaQuery({ query: "(min-width: 768px)" });
  const isLargeWidth = useMediaQuery({ query: "(min-width: 1024px)" });
  const isExtraWidth = useMediaQuery({ query: "(min-width: 1280px)" });

  return (
    <section className="md:h-full text-plack lg:h-[700px] overflow-x-hidden">
      <div className="max-w-7xl py-20 sm:block md:block lg:flex mx-auto">
        <div className="sm:w-full md:w-full lg:w-1/2 relative">
          <div
            ref={ref}
            style={{
              left: isInView ? "0" : "-200px",
              transition: "1s ease-in-out",
            }}
            className="relative">
            <div className="relative mx-auto z-0 left-0 sm:top-0 md:top-0 lg:top-16">
              <Swiper
                spaceBetween={30}
                effect={"fade"}
                navigation={isExtraWidth ? false : isLargeWidth ? false : true}
                loop={true}
                modules={[EffectFade, Navigation, Pagination]}
                style={{ height: "300px" }}
                className="mySwiper">
                <SwiperSlide>
                  <div className="bg-zinc-100 sm:mx-auto sm:rounded-xl sm:rounded-bl-none sm:w-[500px] md:w-fit md:mx-auto md:rounded-xl md:rounded-bl-none lg:w-[470px] lg:mx-auto xl:w-[470px] ">
                    <img src={carF} alt="car" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="bg-zinc-100  sm:mx-auto sm:rounded-xl sm:rounded-bl-none sm:w-[500px] md:w-fit md:mx-auto md:rounded-xl md:rounded-bl-none lg:w-[470px] xl:w-[470px] ">
                    <img src={carL} alt="car" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="bg-zinc-100 sm:mx-auto sm:rounded-xl sm:rounded-bl-none sm:w-[500px] md:w-fit md:mx-auto md:rounded-xl md:rounded-bl-none lg:w-[470px] xl:w-[470px] ">
                    <img src={carB} alt="car" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="bg-zinc-100 sm:mx-auto sm:rounded-xl sm:rounded-bl-none sm:w-[500px] md:w-fit md:rounde-2xl md:rounded-bl-none md:mx-auto lg:w-[470px] xl:w-[470px] ">
                    <img src={carR} alt="car" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <img
              className="absolute hidden top-0 lg:block left-1/2 -translate-x-1/2 -z-20"
              src={back}
              alt="background"
            />
            <img
              className="hidden sm:block absolute top-28 w-52 left-1/2 -translate-x-1/2 sm:top-28 sm:left-1/2 sm:-translate-x-1/2 md:top-56 lg:top-56 md:left-1/2 md:-translate-x-1/2 lg:w-96  xl:w-96 z-0 xl:block md:z-10 lg:-z-10"
              src={circle}
              alt="circle background"
            />
            {show ? (
              <>
                <FontAwesomeIcon
                  className="rotate-45 absolute z-50 top-20 left-[60%] sm:top-16 md:top-[12rem] sm:left-[55%] md:left-[60%] lg:left-[58%] text-white bg-bluePirelly p-1 rounded-full"
                  icon={faPlus}
                />
                <FontAwesomeIcon
                  className="text-white absolute z-50 top-32 left-[35%] sm:top-28 sm:left-[40%] md:left-[40%] lg:left-[40%] xl:top-[185px] bg-bluePirelly p-1 rounded-full"
                  icon={faPlus}
                />
              </>
            ) : (
              ""
            )}
            <div className="w-fit hidden sm:block absolute  left-[47%] top-40 sm:left-[49%] sm:top-[10rem] md:top-[16.8rem] lg:top-[330px] md:left-[49%] lg:left-1/2 -translate-x-1/2">
              <FontAwesomeIcon
                className=" text-bluePirelly absolute bg-bluePirelly p-2 rounded-full"
                icon={faCircle}
              />
              <span className="text-white absolute  left-1.5 top-2 text-xs">
                360
              </span>
            </div>
            <div className="absolute transition-all duration-200 -translate-x-1/2 left-1/2  overflow-hidden bottom-[20%] xm:bottom-[0%] sm:bottom-10 md:left-1/2 md:-top-5 lg:top-[26rem] xl:top-[26rem] flex justify-center lg:w-[100%] h-10 sm:h-12 items-center">
              <div className="flex shadowDef rounded-md bg-[#0085FF1A] items-center gap-3 p-2">
                <svg
                  style={{ color: "#07A4FC" }}
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.1125 11.875H14.9875V13.125H13.1125V15H11.8625V13.125H9.9875V11.875H11.8625V10H13.1125V11.875ZM7.5 3.75C5.43125 3.75 3.75 5.43125 3.75 7.5C3.75 9.56875 5.43125 11.25 7.5 11.25C9.56875 11.25 11.25 9.56875 11.25 7.5C11.25 5.43125 9.56875 3.75 7.5 3.75ZM7.5 8.4375C6.98125 8.4375 6.5625 8.01875 6.5625 7.5C6.5625 6.98125 6.98125 6.5625 7.5 6.5625C8.01875 6.5625 8.4375 6.98125 8.4375 7.5C8.4375 8.01875 8.01875 8.4375 7.5 8.4375ZM8.75 12.5C8.75 12.4438 8.75625 12.3875 8.7625 12.3312C8.35625 12.4375 7.9375 12.5 7.5 12.5C4.74375 12.5 2.5 10.2563 2.5 7.5C2.5 4.74375 4.74375 2.5 7.5 2.5C10.2563 2.5 12.5 4.74375 12.5 7.5C12.5 7.93125 12.4375 8.34375 12.3375 8.74375C12.3938 8.74375 12.45 8.74375 12.5 8.74375C13.4625 8.74375 14.3375 9.10625 15 9.70625V1.875C15 0.84375 14.1562 0 13.125 0H1.875C0.84375 0 0 0.84375 0 1.875V15H9.7125C9.11875 14.3375 8.75 13.4625 8.75 12.5Z"
                    fill="#0085FF"
                  />
                </svg>
                <h4 className="w-16 text-bluePirelly mx-auto">Hotspot</h4>
                <div class="checkbox-wrapper-4">
                  <input
                    onClick={() => setShow((current) => !current)}
                    type="checkbox"
                    id="cbx-4"
                  />
                  <label for="cbx-4" class="toggle1">
                    <span></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          {show ? (
            <div
              style={
                isLargeWidth
                  ? {
                      left: isInView ? "60%" : "-200px",
                      transition: ".8s ease-in-out",
                    }
                  : isMidWidth
                  ? {
                      right: isInView ? "12rem" : "-200px",
                      transition: ".8s ease-in-out",
                    }
                  : isMiniWidth
                  ? {
                      left: isInView ? "28rem" : "20em",
                      transition: ".8s ease-in-out",
                    }
                  : {}
              }
              className="w-[150px] left-[10em] top-0 hidden sm:block sm:w-[150px] md:w-[180px] lg:w-[220px] xl:w-[220px] absolute sm:left-[20em] md:left-[35em] lg:right-6 xl:right-6 sm:-top-16 md:-top-16 lg:-top-20 sm:px-2 md:px-2 sm:text-center md:text-center lg:px-4 xl:px-8 rounded-xl items-center z-10 py-3 bg-zinc-600">
              <div className="sm:w-[70%] md:w-[80%] lg:w-fit xl:w-fit mx-auto">
                <img src={wheel} alt="car wheel" />
              </div>
              <h4 className="text-white my-2 font-semibold text-xs">
                Heated Steering Wheel
              </h4>
              <p className="text-[10px] text-white">
                Lörem ipsum fanöska tetrad: monar. Lod jåck douche ladigt,
              </p>
            </div>
          ) : (
            ""
          )}
          {show ? (
            <div
              style={{
                opacity: isInView ? "1" : "0",
                transition: "2s ease-in-out",
              }}
              className="bg-bluePirelly hidden lg:block lg:w-[28%] lg:left-[51%] -rotate-[65deg] absolute top-[129px] z-0 h-[2px]"></div>
          ) : (
            ""
          )}
        </div>
        <div className="w-full sm:w-full md:w-full py-10 text-center sm:text-center md:text-center lg:text-start lg:w-1/2">
          <span className="text-bluePirelly font-medium text-xl">
            We made it simple
          </span>
          <h2 className="text-2xl sm:text-2xl md:text-2xl lg:text-4xl xl:text-5xl font-bold my-6">
            Virtual Walk-Around
          </h2>
          <p className="w-[80%] mx-auto lg:mx-0 sm:w-[80%] sm:mx-auto md:w-[80%] md:mx-auto lg:max-w-[500px]">
            Elevate the car buying experience with our Virtual Walk Around
            solution. Say goodbye to static images and welcome a dynamic
            360-degree virtual turntable that brings vehicles to life, allowing
            customers to explore every angle, creating engagement like never
            before.
          </p>
          <p className="py-10 text-xs font-medium grid grid-cols-1 sm:grid sm:grid-cols-1 md:grid md:grid-cols-1 lg:flex gap-5">
            <span>
              <FontAwesomeIcon
                className="text-bluePirelly pr-2"
                icon={faCheck}
              />
              10X Customer Engagement
            </span>
            <span>
              <FontAwesomeIcon
                className="text-bluePirelly pr-2"
                icon={faCheck}
              />
              Hot Spot/Damage Tagging
            </span>
            <span>
              <FontAwesomeIcon
                className="text-bluePirelly pr-2"
                icon={faCheck}
              />
              A.I Background removal
            </span>
          </p>
          <button className="button blueButton">
            <Link to="/imaging">Learn more</Link>
          </button>
        </div>
      </div>
    </section>
  );
}

export default Virtual;
