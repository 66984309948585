// import logo from "../../assets/logo-pirelly.webp";
import { Player } from "@lottiefiles/react-lottie-player";
import CarAnimtion from "../../Json/car.json";
import ChatBot from "../../Json/chat-bot.json";
import Cooding from "../../Json/coding.json";
import bg from "../../assets/bg-icons-products.webp";
import "./animateLineMove.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function PirellyProducts() {
  return (
    <section>
      <div className="max-w-6xl text-plack py-20 overflow-hidden mx-auto relative">
        <h2 className="text-center text-2xl sm:text-3xl lg:text-4xl font-bold py-6">
          Our Product Line
        </h2>
        <div className="xl:flex xl:w-full xl:justify-between lg:grid lg:gap-10 lg:w-fit lg:justify-center lg:mx-auto lg:grid-cols-2 grid grid-cols-1 w-[90%] gap-8 mx-auto justify-center md:grid md:grid-cols-1 md:w-fit md:gap-3 md:mx-auto md:justify-center py-6 xl:gap-10">
          <div className="flex h-[150px] w-full sm:w-[60%] sm:mx-auto md:w-[400px] md:mx-auto lg:mx-0 xl:mx-0 xl:w-full lg:w-full items-center text-center relative justify-center bg-whitePirelly shadow-lg rounded-2xl">
            <div className="flex items-center w-2/6 h-full">
              <Player
                src={CarAnimtion}
                className="relative left-8 w-16 h-16"
                loop
                autoplay
              />
              <img
                className="absolute left-0 top-0"
                src={bg}
                alt="background blue"
              />
            </div>
            <div className="flex-1">
              <h2 className="font-semibold text-sm sm:text-lg">
                Virtual Walk-Around
              </h2>
              <p className="text-xs sm:text-sm  py-3">
                Elevate the car buying experience{" "}
              </p>
              <div className="flex items-center justify-center gap-2">
                <Link to="/imaging" className="text-sm font-medium">
                  See more
                </Link>
                <FontAwesomeIcon size="xs" icon={faArrowRight} />
              </div>
            </div>
          </div>
          <div className="flex h-[150px] sm:w-[60%] sm:mx-auto md:w-[400px] md:mx-auto lg:mx-0 xl:mx-0 xl:w-full lg:w-full items-center text-center relative justify-center bg-whitePirelly shadow-lg rounded-2xl">
            <div className="flex items-center w-2/6 h-full">
              <Player
                src={ChatBot}
                className="relative left-8 w-16 h-16"
                loop
                autoplay
              />
              <img
                className="absolute left-0 top-0"
                src={bg}
                alt="background blue"
              />
            </div>
            <div className="flex-1">
              <h2 className="font-semibold">Intelligent Chat 2.0</h2>
              <p className="text-sm py-3">online support for your site </p>
              <div className="flex items-center justify-center gap-2">
                <Link to="/aichat" className="text-sm font-medium">
                  See more
                </Link>
                <FontAwesomeIcon size="xs" icon={faArrowRight} />
              </div>
            </div>
          </div>
          <div className="flex h-[150px] sm:w-[60%] sm:mx-auto md:w-[400px] md:mx-auto lg:mx-0 xl:mx-0 xl:w-full lg:w-full items-center text-center relative justify-center bg-whitePirelly shadow-lg rounded-2xl">
            <div className="flex items-center w-2/6 h-full">
              <Player
                src={Cooding}
                className="relative left-8 w-16 h-16"
                loop
                autoplay
              />
              <img
                className="absolute left-0 top-0"
                src={bg}
                alt="background blue"
              />
            </div>
            <div className="flex-1">
              <h2 className="font-semibold">Digital Retail</h2>
              <p className="text-sm py-3">Selling with the best facilities</p>
              <div className="flex items-center justify-center gap-2 text-slate-500 text-sm font-medium">
                Coming Soon ..
                {/* <FontAwesomeIcon size="xs" icon={faArrowRight} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PirellyProducts;
