import Footer from "../homePage/Footer";
// import { useInView } from "framer-motion";
// import { useRef } from "react";
import "./bgCricle.css";

export default function TremsOfServise() {
  // const ref = useRef(null);
  // const isInView = useInView(ref);

  return (
    <section className="text-plack">
      <div className="bg-bluePirelly Circle py-28 rounded-b-2xl text-center text-white">
        <h2 className="text-4xl pt-10 font-bold pb-5">Trems Of Servise</h2>
        <p>Learn Everything You Want From The WeDryve Platform</p>
      </div>
      {/* it matches with body script's */}
      <iframe
        src="https://app.termly.io/document/terms-of-service/1d70d89a-4892-44b9-9886-17ffa42ae7b8"
        className="overscroll-y-contain"
        title="terms of service"
        height="1200px"
        width="100%"
        allowfullscreen={true}></iframe>
      {/* <div
        ref={ref}
        className="grid gap-16 max-w-7xl mx-auto w-[80%] lg:w-full py-16">
        <div
          style={{
            opacity: isInView ? "1" : "0",
            top: isInView ? "0px" : "-100px",
            transition: "ease-in-out 1s",
            position: "relative",
          }}>
          <h2 className="font-bold text-xl py-8">
            Generate Terms & Conditions
          </h2>
          <p className="text-sm">
            By accessing and placing an order with UXTheme, you confirm that you
            are in greement with and bound by the terms and conditions contained
            in the Terms Of Use outlined elow.entire website and any
            emailommunication between you and UXTheme. Under no circumstances
            shall UXTheme team be liable for any direct, indirect, special,
            incidental or consequential damages, including, but not limited to,
            loss of data or profit, arisingout of the use, or the inability to
            use, the materials on this site, even if UXTheme team or an
            authorized representative has been advised of the possibility of
            such damages. If your use of materials from this site results in the
            need for servicing, repair or correction of equipment or data, you
            assume any costs thereof
          </p>
        </div>
        <div
          style={{
            opacity: isInView ? "1" : "0",
            top: isInView ? "0px" : "-100px",
            transition: "ease-in-out 1.5s",
            position: "relative",
          }}>
          <h2 className="font-bold text-xl py-8">
            Generate Terms & Conditions for websites
          </h2>
          <p className="text-sm">
            Creating a Terms & Conditions for your application or website can
            take a lot of time. You could either spend tons of money on hiring a
            lawyer, or you could simply use our service and get a unique Terms &
            Conditions fully custumized to your website. Creating a Terms &
            Conditions for your application or website can take a lot of time.
            You could either spend tons of money on hiring a lawyer, or you
            could simply use our service and get a unique Terms & Conditions
            fully custumized to your website.
          </p>
        </div>
        <div
          style={{
            opacity: isInView ? "1" : "0",
            top: isInView ? "0px" : "-100px",
            transition: "ease-in-out 2s",
            position: "relative",
          }}>
          <h2 className="font-bold text-xl py-8">
            Generate Terms & Conditions for apps
          </h2>
          <p className="text-sm">
            Creating a Terms & Conditions for your application or website can
            take a lot of time. You could either spend tons of money on hiring a
            lawyer, or you could simply use our service and get a unique Terms &
            Conditions fully custumized to your website. Creating a Terms &
            Conditions for your application or website can take a lot of time.
            You could either spend tons of money on hiring a lawyer, or you
            could simply use our service and get a unique Terms & Conditions
            fully custumized to your website.
          </p>
        </div>
        <div
          style={{
            opacity: isInView ? "1" : "0",
            top: isInView ? "0px" : "-100px",
            transition: "ease-in-out 2.5s",
            position: "relative",
          }}>
          <h2 className="font-bold text-xl py-8">
            Generate Terms & Conditions for websites
          </h2>
          <p className="text-sm">
            Creating a Terms & Conditions for your application or website can
            take a lot of time. You could either spend tons of money on hiring a
            lawyer, or you could simply use our service and get a unique Terms &
            Conditions fully custumized to your website. Creating a Terms &
            Conditions for your application or website can take a lot of time.
            You could either spend tons of money on hiring a lawyer, or you
            could simply use our service and get a unique Terms & Conditions
            fully custumized to your website.
          </p>
        </div>
      </div> */}
      <Footer />
    </section>
  );
}
