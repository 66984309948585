import carPhoto from "../../assets/cheerful-french-lady-pointing-with-finger-smartphone-smiling-blonde-girl-beret-standing-red-wall copy 2 (1).webp";
import chatDis from "../../assets/chatbackHead.webp";
import chat1 from "../../assets/chat1 1.webp";
import chat2 from "../../assets/chat2 1.webp";
import chat3 from "../../assets/chat3 1.webp";
import chat4 from "../../assets/chat4 1.webp";
import chat5 from "../../assets/chat5 1.webp";
import person from "../../assets/coment-1.webp";
import person2 from "../../assets/coment-2.webp";
import person3 from "../../assets/coment-3.webp";
import pic2 from "../../assets/carsection2.webp";
import pic from "../../assets/car-section.webp";
import message from "../../assets/message.webp";
import backgroundCar from "../../assets/Rectangle 1318.webp";
import { useEffect, useState } from "react";
import "./rangeStyle.css";
import { useRef } from "react";
import { useInView } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

function Header() {
  const [valueRange, setValueRange] = useState(17000);
  const [counterState, setCounter] = useState(80);

  useEffect(() => {
    let timer;
    clearInterval(timer);
    timer = setInterval(() => {
      if (counterState === 112) {
        clearInterval(timer);
        return;
      }
      setCounter((prev) => prev + 1);
    }, 10);
    return () => clearInterval(timer);
  }, [counterState]);

  const isExtraMiniWidth = useMediaQuery({ query: "(min-width: 280px)" });
  const isMiniWidth = useMediaQuery({ query: "(min-width: 500px)" });
  const isMidWidth = useMediaQuery({ query: "(min-width: 768px)" });
  const isLargeWidth = useMediaQuery({ query: "(min-width: 1024px)" });
  const isExtraWidth = useMediaQuery({ query: "(min-width: 1280px)" });

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section className="flex text-plack items-center lg:max-h-[100vh]">
      <div className="max-w-7xl w-full overflow-hidden px-4 sm:px-4 md:px-4 xl:px-2 h-full mx-auto">
        <div className="h-10 sm:h-10 md:h-14 lg:h-32 xl:h-32"></div>
        <div className="w-full text-center block sm:w-full sm:text-center sm:block md:w-full md:text-center lg:text-start md:block lg:flex xl:flex items-center relative">
          <div
            ref={ref}
            className="w-full mx-auto sm:w-full sm:mx-auto md:w-full md:mx-auto lg:w-2/5 xl:w-2/5">
            <h2 className="sm:text-3xl sm:w-1/2 sm:mx-auto md:text-3xl md:w-1/2 lg:w-full md:mx-auto lg:text-3xl xl:text-4xl z-10 relative py-8 font-extrabold">
              Driving <div className="bgTitle">Dealership </div>Growth <br />{" "}
              Using The Power Of A.I
            </h2>
            <p className="font-medium text-sm md:text-base sm:w-[80%] md:w-[80%] mx-auto md:mx-0">
              Revolutionizing the automotive industry with cutting-edge AI
              solutions: immersive virtual walk arounds, intelligent chat, and
              seamless digital retail!
            </p>
            <div className="py-10 w-fit mx-auto lg:mx-0 flex gap-5">
              <button className="button blueButton">
                <Link to="/imaging">learn more</Link>
              </button>
              {/* <button className="items-center  flex gap-4">
                <FontAwesomeIcon
                  className="text-white scale-50 bg-bluePirelly rounded-full  w-4 p-4 ring-[25px] ring-shadowPirelly items-center h-4"
                  icon={faPlay}
                />
                Watch intro
              </button> */}
            </div>
            <div className="relative w-fit flex mx-auto lg:mx-0">
              <div className="flex w-28">
                <img
                  className="absolute w-12 left-12 outline-8 border-4 border-white rounded-full"
                  src={person2}
                  alt="avatar"
                />
                <img
                  className="absolute w-12 left-6 outline-8 border-4 border-white rounded-full"
                  src={person}
                  alt="avatar"
                />
                <img
                  className="relative w-12 left-0 outline-8 border-4 border-white rounded-full"
                  src={person3}
                  alt="avatar"
                />
              </div>
              <div>
                <h3 className="font-medium">
                  <span>{isInView && counterState}</span> dealers on the wait
                  list
                </h3>
                <p className="text-sm text-zinc-500">Join the movement.</p>
              </div>
            </div>
          </div>
          <div
            ref={ref}
            className="w-full py-8 sm:py-0 md:w-full lg:w-3/5 sm:h-[500px] flex items-center  relative">
            <div className="absolute hidden sm:block top-0 lg:left-0 xl:left-1/2 left-1/2 md:left-1/2 -translate-x-1/2 md:-translate-x-1/2 lg:-translate-x-0 xl:-translate-x-1/2">
              <img
                className="rounded-full w-[100%]"
                src={backgroundCar}
                alt="carPhoto"
              />
            </div>
            <Swiper
              spaceBetween={25}
              loop={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2500,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
              }}
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
              className="mySwiper bg-zinc-100 rounded-[50px] sm:bg-transparent"
              style={
                isLargeWidth
                  ? {
                      width: "100%",
                      height: "500px",
                    }
                  : isMidWidth
                  ? {
                      width: "80%",
                      height: "500px",
                    }
                  : isMiniWidth
                  ? { width: "80%", height: "400px" }
                  : isExtraMiniWidth
                  ? { width: "80%", height: "250px" }
                  : {}
              }>
              <SwiperSlide>
                <div className="flex w-[80%] mx-auto items-center justify-center">
                  <div className="w-[500px] md:w-[500px] lg:w-[400px] xl:w-full relative">
                    <LazyLoadImage
                      className="relative left-5 sm:left-10 md:left-10 lg:left-0 top-16"
                      effect="blur"
                      src={carPhoto}
                      alt="carPhoto"
                    />
                    <div
                      style={
                        isExtraWidth
                          ? {
                              left: isInView ? "0%" : "10rem",
                              transition: "ease-in-out .5s",
                            }
                          : isLargeWidth
                          ? {
                              left: isInView ? "0%" : "10rem",
                              transition: "ease-in-out .5s",
                            }
                          : isMidWidth
                          ? {
                              left: isInView ? "15%" : "20rem",
                              transition: "ease-in-out .5s",
                            }
                          : isExtraMiniWidth
                          ? {
                              left: isInView ? "8%" : "20rem",
                              transition: "ease-in-out .5s",
                            }
                          : {}
                      }
                      className="bg-bluePirelly text-white absolute flex p-2 sm:p-4 md:p-4 lg:p-2 xl:p-4 items-center gap-2 rounded-lg z-20 top-10">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g id="Vector">
                          <path
                            id="Vector_2"
                            d="M15.735 14.25H17.985V15.75H15.735V18H14.235V15.75H11.985V14.25H14.235V12H15.735V14.25ZM9 4.5C6.5175 4.5 4.5 6.5175 4.5 9C4.5 11.4825 6.5175 13.5 9 13.5C11.4825 13.5 13.5 11.4825 13.5 9C13.5 6.5175 11.4825 4.5 9 4.5ZM9 10.125C8.3775 10.125 7.875 9.6225 7.875 9C7.875 8.3775 8.3775 7.875 9 7.875C9.6225 7.875 10.125 8.3775 10.125 9C10.125 9.6225 9.6225 10.125 9 10.125ZM10.5 15C10.5 14.9325 10.5075 14.865 10.515 14.7975C10.0275 14.925 9.525 15 9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 9.5175 14.925 10.0125 14.805 10.4925C14.8725 10.4925 14.94 10.4925 15 10.4925C16.155 10.4925 17.205 10.9275 18 11.6475V2.25C18 1.0125 16.9875 0 15.75 0H2.25C1.0125 0 0 1.0125 0 2.25V18H11.655C10.9425 17.205 10.5 16.155 10.5 15Z"
                            fill="white"
                          />
                        </g>
                      </svg>
                      <span>Hotspot</span>
                    </div>
                    <div
                      style={
                        isExtraWidth
                          ? {
                              right: isInView ? "0%" : "10rem",
                              transition: "ease-in-out .5s",
                            }
                          : isLargeWidth
                          ? {
                              right: isInView ? "-3.5em" : "10rem",
                              transition: "ease-in-out .5s",
                            }
                          : isMidWidth
                          ? {
                              left: isInView ? "80%" : "0",
                              transition: "ease-in-out .5s",
                            }
                          : isExtraMiniWidth
                          ? {
                              left: isInView ? "50%" : "20rem",
                              transition: "ease-in-out .5s",
                            }
                          : {}
                      }
                      className="bg-bluePirelly text-white absolute flex sm:left-[80%] w-[150px] p-2 sm:p-4 md:left-[80%] md:p-4 lg:p-2 xl:p-4 items-center gap-2 rounded-lg z-20 top-0 lg:right-24">
                      <svg
                        width="28"
                        height="19"
                        viewBox="0 0 28 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g id="Group">
                          <g id="Group_2">
                            <path
                              id="Vector"
                              d="M22.5114 7.71277C22.206 7.62537 21.8878 7.80218 21.8005 8.10751C21.7131 8.41283 21.8899 8.73115 22.1952 8.81844C24.8197 9.56904 26.45 10.6577 26.45 11.6597C26.45 12.3929 25.5582 13.1978 24.0033 13.8678C22.2926 14.605 19.8657 15.1443 17.1671 15.3872L14.8117 15.3553C14.809 15.3553 14.8064 15.3553 14.8037 15.3553C14.4898 15.3553 14.2332 15.6076 14.2289 15.9225C14.2246 16.24 14.4786 16.501 14.7961 16.5052L17.1811 16.5375C17.1837 16.5375 17.1863 16.5376 17.1889 16.5376C17.206 16.5376 17.223 16.5368 17.24 16.5353C20.0688 16.2831 22.6323 15.7108 24.4584 14.924C27.055 13.805 27.6 12.5312 27.6 11.6597C27.6 10.053 25.7928 8.65123 22.5114 7.71277Z"
                              fill="white"
                            />
                            <path
                              id="Vector_2"
                              d="M5.57048 7.0579C5.25297 7.0579 4.99548 7.31533 4.99548 7.6329C4.99548 7.95047 5.25297 8.2079 5.57048 8.2079H7.93494V10.5845H5.57048C5.25297 10.5845 4.99548 10.842 4.99548 11.1595C4.99548 11.4771 5.25297 11.7345 5.57048 11.7345H8.50994C8.82751 11.7345 9.08494 11.4771 9.08494 11.1595V4.10625C9.08494 3.78868 8.82751 3.53125 8.50994 3.53125H5.57048C5.25297 3.53125 4.99548 3.78868 4.99548 4.10625C4.99548 4.42382 5.25297 4.68125 5.57048 4.68125H7.93494V7.0579H5.57048Z"
                              fill="white"
                            />
                            <path
                              id="Vector_3"
                              d="M10.8605 11.7346H14.3872C14.7047 11.7346 14.9622 11.4772 14.9622 11.1596V7.63296C14.9622 7.31538 14.7048 7.05796 14.3872 7.05796H11.4355V4.68125H13.8C14.1176 4.68125 14.375 4.42382 14.375 4.10625C14.375 3.78868 14.1176 3.53125 13.8 3.53125H10.8605C10.5429 3.53125 10.2855 3.78868 10.2855 4.10625V11.1596C10.2855 11.4772 10.5429 11.7346 10.8605 11.7346ZM11.4355 8.2079H13.8122V10.5845H11.4355V8.2079Z"
                              fill="white"
                            />
                            <path
                              id="Vector_4"
                              d="M16.7394 11.7346H20.2661C20.5836 11.7346 20.8411 11.4772 20.8411 11.1596V4.10625C20.8411 3.78868 20.5836 3.53125 20.2661 3.53125H16.7394C16.4219 3.53125 16.1644 3.78868 16.1644 4.10625V11.1596C16.1644 11.4772 16.4219 11.7346 16.7394 11.7346ZM17.3144 4.68125H19.6911V10.5846H17.3144V4.68125Z"
                              fill="white"
                            />
                            <path
                              id="Vector_5"
                              d="M10.5143 13.1639C10.3092 12.9214 9.94635 12.8911 9.70393 13.0962C9.46145 13.3013 9.43121 13.6641 9.63625 13.9066L10.9223 15.4269C8.20853 15.2199 5.75069 14.7226 3.94352 14.0095C2.16821 13.3089 1.15 12.4523 1.15 11.6591C1.15 10.738 2.51482 9.74548 4.80085 9.00396C5.10295 8.90598 5.26838 8.58168 5.1704 8.27957C5.07248 7.97752 4.74806 7.8121 4.44607 7.91002C3.11615 8.34138 2.06155 8.85457 1.31146 9.43526C0.441255 10.1092 0 10.8573 0 11.6591C0 12.9876 1.21774 14.1702 3.52141 15.0792C5.39384 15.818 7.90447 16.3366 10.6644 16.5603L9.51763 17.8312C9.30488 18.067 9.32351 18.4306 9.55932 18.6433C9.66926 18.7426 9.80703 18.7914 9.94434 18.7914C10.1014 18.7914 10.2579 18.7274 10.3714 18.6016L12.4636 16.2829C12.559 16.1773 12.6118 16.0401 12.6118 15.8977V15.8538C12.6118 15.7178 12.5636 15.5863 12.4758 15.4825L10.5143 13.1639Z"
                              fill="white"
                            />
                            <path
                              id="Vector_6"
                              d="M21.9313 3.5014H24.2819C24.5994 3.5014 24.8569 3.24398 24.8569 2.92641V0.575C24.8569 0.257428 24.5994 0 24.2819 0H21.9313C21.6138 0 21.3563 0.257428 21.3563 0.575V2.92641C21.3563 3.24398 21.6138 3.5014 21.9313 3.5014ZM22.5063 1.15H23.7069V2.35141H22.5063V1.15Z"
                              fill="white"
                            />
                          </g>
                        </g>
                      </svg>
                      <span>360image</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex w-[80%] md:w-[80%] mx-auto lg:w-fit items-center justify-center">
                  <div className="relative w-fit">
                    <div className="w-[200px] sm:w-[300px] md:w-[300px] lg:w-[70%] relative left-0 lg:left-1/2 lg:-translate-x-1/2">
                      <LazyLoadImage
                        effect="blur"
                        src={chatDis}
                        alt="carPhoto"
                      />
                    </div>
                    <div
                      style={{
                        opacity: isInView ? "1" : "0",
                        transition: "ease-in-out 1s",
                      }}
                      className="absolute left-[10%] top-12 sm:left-[12%] md:left-[12%] lg:left-28 xl:left-[22.5%] sm:top-20 xl:top-24">
                      <div className="w-[60%] sm:w-[55%] md:w-[55%] lg:w-3/5 xl:w-3/5">
                        <img
                          className="rounded-full"
                          src={chat1}
                          alt="avatar"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        opacity: isInView ? "1" : "0",
                        transition: "ease-in-out 1s",
                      }}
                      className="absolute left-[45%] top-[4.3rem] sm:left-[45%] sm:top-28 xl:top-32 md:left-[54%] lg:left-[13rem] xl:right-[22%] ">
                      <div className="w-[65%] sm:w-[70%] md:w-[60%] lg:w-3/5 xl:w-4/5">
                        <img
                          className="rounded-full"
                          src={chat2}
                          alt="avatar"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        opacity: isInView ? "1" : "0",
                        transition: "ease-in-out 1s",
                      }}
                      className="absolute left-[14.5%] top-[5.5rem] sm:left-[18%] md:left-[14%] lg:left-[7.6rem] xl:left-[24%] sm:top-36">
                      <div className="w-[70%] sm:w-[70%] md:w-[60%] lg:w-3/5 xl:w-3/5">
                        <img
                          className="rounded-full"
                          src={chat3}
                          alt="avatar"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        opacity: isInView ? "1" : "0",
                        transition: "ease-in-out 1s",
                      }}
                      className="absolute left-[15%] sm:left-[18%] top-28 md:left-[16%] lg:left-32 xl:left-[25%] sm:top-44">
                      <div className="w-[65%] sm:w-[65%] md:w-[55%] lg:w-3/5 xl:w-3/5">
                        <img
                          className="rounded-full"
                          src={chat4}
                          alt="avatar"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        opacity: isInView ? "1" : "0",
                        transition: "ease-in-out 1s",
                      }}
                      className="absolute left-[55%] top-[8.5rem] sm:left-[55%] md:left-[60%] md:top-56 lg:left-[15.5rem] xl:left-[15.5rem] sm:top-52">
                      <div className="w-[65%] sm:w-[65%] md:w-[50%] lg:w-3/5 xl:w-3/5">
                        <img
                          className="rounded-full"
                          src={chat5}
                          alt="avatar"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        opacity: isInView ? "1" : "0",
                        transition: "ease-in-out 1s",
                      }}
                      className="absolute left-[22%] bottom-[30%] sm:left-[18%] md:left-[18%] lg:left-36 sm:bottom-32">
                      <div className="w-[50%] sm:w-[60%] md:w-[58%] lg:w-3/5 xl:w-4/5">
                        <img
                          className="rounded-full"
                          src={message}
                          alt="avatar"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex w-[80%] mx-auto items-center justify-center">
                  <div className="w-full h-full">
                    <div className="relative w-[80%] top-5 left-0 sm:w-[70%] sm:left-0 md:w-[70%] lg:w-[55%] sm:top-32 z-20 md:left-[10%] lg:left-0">
                      <LazyLoadImage effect="blur" src={pic} alt="carPhoto" />
                    </div>
                    <div className="absolute -top-5 left-20 w-[80%] sm:w-[70%] sm:left-[30%] sm:top-0 md:w-[70%] lg:w-[75%] md:left-[30%] lg:left-44 md:top-0 lg:top-[0rem]">
                      <LazyLoadImage effect="blur" src={pic2} alt="carPhoto" />
                    </div>
                    <div className="sm:grid animationOne gap-3 lg:w-[20%] xl:w-fit p-4 bg-whitePirelly absolute hidden  top-0 right-0 sm:right-[24em] rounded-lg shadow-2xl font-medium">
                      <h3 className="text-sm text-center">Down Payment</h3>
                      <input
                        className="h-1.5 w-full rangeStyle border-none outline-none"
                        type="range"
                        onChange={(e) => setValueRange(e.target.value)}
                        min={0}
                        max={30000}
                        value={valueRange}
                      />
                      <h3 className="text-center text-sm">{valueRange}$</h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
