import AichatBot from "../homePage/AIchatBot";
import ManageProject from "./ManageProject";
import Provide from "./Provide";
import Question from "./Question";
import QuestionHome from "../homePage/Questions";
import Reasons from "./Reasons";
import Unique from "./Unique";
import Comments from "../homePage/Comments";
import Footer from "../homePage/Footer";
import YouMay from "../imagingPage/YouMay";
import { BookDemo } from "../homePage/BookDemo";

export default function AiChatPage() {
  return (
    <>
      <AichatBot />
      <Reasons />
      <Unique />
      <Provide />
      <ManageProject />
      <Question />
      <Comments />
      <YouMay />
      <QuestionHome />
      <BookDemo />
      <Footer />
    </>
  );
}
