import { useRef } from "react";
import { useInView } from "framer-motion";
import { useMediaQuery } from "react-responsive";

export default function WalkArounds() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const isExtraMiniWidth = useMediaQuery({ query: "(min-width: 280px)" });
  const isMidWidth = useMediaQuery({ query: "(min-width: 768px)" });
  const isExtraWidth = useMediaQuery({ query: "(min-width: 1280px)" });

  return (
    <section className="sm:max-h-[100vh] text-plack bg-zinc-100 flex items-center mx-auto overflow-hidden">
      <div className="max-w-7xl text-center relative py-16 mx-auto">
        <span className="text-bluePirelly font-semibold text-sm">
          360 walkarounds to engage online shoppers
        </span>
        <h2 className="font-bold py-5 md:text-3xl lg:text-4xl">
          Create an Ultimate Buying Experience
        </h2>
        <div
          ref={ref}
          style={{
            opacity: isInView ? "1" : "0",
            transition: "opactiy ease-in-out 1s",
          }}
          className="lg:w-[890px] lg:h-[500px]">
          <iframe
            title="car 360 view"
            allowFullScreen
            className="mx-auto"
            style={{
              backgroundColor: "#f4f4f5",
              width: isExtraWidth
                ? "100%"
                : isMidWidth
                ? "768px"
                : isExtraMiniWidth
                ? "380px"
                : "",
              borderRadius: "20px",
              height: isExtraWidth
                ? "100%"
                : isMidWidth
                ? "430px"
                : isExtraMiniWidth
                ? "212px"
                : "",
              bottom: 0,
              left: 0,
              top: 0,
              right: 0,
              position: "relative",
            }}
            src="https://iframe-b8b2c.firebaseapp.com/?DBURL=https://pirelly360-iframes.firebaseio.com&mid=405487984003&uid=zZ1Pl0apcJOHUW6CAB7wwQ9zEN63&style=style1&nodeName=pIframes&viewPage=car"
          />
        </div>
      </div>
    </section>
  );
}
