import React, { useRef } from "react";
import "./home.css";
import Header from "./Header";
import WhyPirelly from "./WhyPirelly";
import WalkArounds from "./WalkArounds";
import Cards from "./Cards";
import UniqeExprince from "./UniqeExprince";
import Dashboard from "./Dashboard";
import CreateYour from "./CreateYour";
import CarHistory from "./CarHistory";
import SellOnline from "./SellOnline";
import ShowCase from "./ShowCase";
import BeforeAfter from "./BeforeAfter";
import CustomerReview from "../homePage/CustomerReview";
import Questions from "../homePage/Questions";
import { BookDemo } from "../homePage/BookDemo";
import Footer from "../homePage/Footer";
import YouMay from "./YouMay";

export default function ImagingPage() {
  const sectionRef = useRef(null, { once: true });

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Header scrollToSection={scrollToSection} />
      <WhyPirelly />
      <WalkArounds />
      <CustomerReview scrollToSection={scrollToSection} />
      <UniqeExprince />
      <Dashboard />
      <CreateYour />
      <CarHistory />
      <SellOnline />
      <ShowCase />
      <BeforeAfter />
      <Cards />
      <YouMay />
      <Questions />
      <BookDemo sectionRef={sectionRef} />
      <Footer />
    </>
  );
}
