import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

function Questions() {
  const [item1, setItem1] = useState(true);
  const [item2, setItem2] = useState(false);
  const [item3, setItem3] = useState(false);

  return (
    <section>
      <div className="max-w-7xl text-plack overflow-hidden w-full py-32 mx-auto">
        <div className="text-center w-full">
          <span className="font-medium text-lg text-bluePirelly">faq..</span>
          <h2 className="text-2xl sm:text-4xl h-[100px] my-2 font-medium">
            Frequently Asked Questions
          </h2>
        </div>
        <div
          style={{
            height: item1 ? "210px" : "88PX",
            boxShadow: item1 ? "" : "none",
          }}
          className="w-[90%] overflow-hidden mx-auto p-8 sm:p-10 shadowQuest  transition-all my-5 duration-200">
          <div className="flex h-[70px] relative text-bluePirelly">
            <h3
              onClick={() => setItem1((current) => !current)}
              style={{ color: item1 ? "#07A4FC" : "black" }}
              className="font-semibold text-sm sm:text-base flex-1">
              How can Image 360 benefit my dealership's sales?
            </h3>
            <div
              onClick={() => setItem1((current) => !current)}
              className=" cursor-pointer text-end">
              <FontAwesomeIcon
                className="absolute -right-5 sm:right-0"
                size="lg"
                icon={faMinus}
              />
              <FontAwesomeIcon
                style={{ rotate: item1 ? "90deg" : "0deg" }}
                className="absolute -right-5 sm:right-0 transition-all duration-200"
                size="lg"
                icon={faMinus}
              />
            </div>
          </div>
          <p className="font-medium text-sm sm:text-base mx-auto w-full">
            Image 360 is a game-changer for car dealerships. It provides an
            immersive 360-degree view of your vehicles, allowing customers to
            explore every detail. This not only enhances their buying experience
            but also builds trust and confidence, ultimately leading to higher
            sales and customer satisfaction.
          </p>
        </div>
        <div
          style={{
            height: item2 ? "210px" : "88PX",
            boxShadow: item2 ? "" : "none",
            borderBlock: item2 ? "none" : "2px solid #07a4fc21",
          }}
          className="w-[90%] overflow-hidden mx-auto p-8 sm:p-10 shadowQuest  transition-all my-5 duration-200">
          <div className="flex h-[70px] relative text-bluePirelly">
            <h3
              onClick={() => setItem2((current) => !current)}
              style={{ color: item2 ? "#07A4FC" : "black" }}
              className="font-semibold text-sm sm:text-base flex-1">
              How does AI Chat drive sales for dealerships?
            </h3>
            <div
              onClick={() => setItem2((current) => !current)}
              className=" cursor-pointer text-end">
              <FontAwesomeIcon
                className="absolute -right-5 sm:right-0"
                size="lg"
                icon={faMinus}
              />
              <FontAwesomeIcon
                style={{ rotate: item2 ? "90deg" : "0deg" }}
                className="absolute -right-5 sm:right-0 transition-all duration-200"
                size="lg"
                icon={faMinus}
              />
            </div>
          </div>
          <p className="font-medium text-sm sm:text-base mx-auto w-full">
            AI Chat revolutionizes customer engagement. It provides instant,
            accurate answers to customer inquiries, guiding them through the
            buying process efficiently. By offering personalized assistance
            24/7, AI Chat ensures that potential buyers have the information
            they need when they need it, which significantly increases the
            likelihood of closing deals and boosting sales.
          </p>
        </div>
        <div
          style={{
            height: item3 ? "210px" : "88PX",
            boxShadow: item3 ? "" : "none",
            borderBottom: item3 ? "none" : "2px solid #07a4fc21",
          }}
          className="w-[90%] overflow-hidden mx-auto p-8 sm:p-10 shadowQuest  transition-all my-5 duration-200">
          <div className="flex h-[70px] relative text-bluePirelly">
            <h3
              onClick={() => setItem3((current) => !current)}
              style={{ color: item3 ? "#07A4FC" : "black" }}
              className="font-semibold text-sm sm:text-base flex-1">
              What sets your Digital Retail solution apart from the competition?
            </h3>
            <div
              onClick={() => setItem3((current) => !current)}
              className=" cursor-pointer text-end">
              <FontAwesomeIcon
                className="absolute -right-5 sm:right-0"
                size="lg"
                icon={faMinus}
              />
              <FontAwesomeIcon
                style={{ rotate: item3 ? "90deg" : "0deg" }}
                className="absolute -right-5 sm:right-0 transition-all duration-200"
                size="lg"
                icon={faMinus}
              />
            </div>
          </div>
          <p className="font-medium text-sm sm:text-base mx-auto w-full">
            Our Digital Retail solution is comprehensive and customer-centric.
            It allows dealerships to offer a fully online car buying experience,
            from vehicle selection to financing, all on one platform. This level
            of convenience and efficiency sets your dealership apart, attracting
            more customers and increasing sales while streamlining operations
            for greater profitability.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Questions;
