import { Swiper, SwiperSlide } from "swiper/react";
import car from "../../assets/iframe-big-image.png";
import mac from "../../assets/MacbookWithout.webp";
import macKey from "../../assets/MacKeyboard.webp";

import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, EffectFade } from "swiper/modules";
import { useMediaQuery } from "react-responsive";

export default function ShowCase() {
  const isExtraMiniWidth = useMediaQuery({ query: "(min-width: 280px)" });
  const isMidWidth = useMediaQuery({ query: "(min-width: 768px)" });
  const isLargeWidth = useMediaQuery({ query: "(min-width: 1024px)" });
  const isExtraWidth = useMediaQuery({ query: "(min-width: 1280px)" });

  return (
    <section className="max-h-[100vh] text-plack max-w-[1520px] mx-auto flex items-center overflow-hidden">
      <div className="text-center mx-auto grid items-center relative">
        <div className="pt-10">
          <h2 className="text-2xl px-2 sm:px-0 lg:text-4xl mt-14 font-bold">
            Compatible with every vehicle and every screen
          </h2>
        </div>
        <div className="w-fit pb-20 mx-auto">
          <Swiper
            pagination={true}
            navigation={isExtraWidth ? false : true}
            breakpoints={{
              1280: {
                slidesPerView: 3,
              },
              750: {
                slidesPerView: 1,
              },
            }}
            // slidesPerView={3}
            spaceBetween={65}
            initialSlide={1}
            modules={[Pagination, Navigation, EffectFade]}
            style={
              isExtraWidth
                ? {
                    width: "1280px",
                  }
                : isLargeWidth
                ? { width: "1024px" }
                : isMidWidth
                ? { width: "768px" }
                : isExtraMiniWidth
                ? { width: "380px", height: "250px" }
                : {}
            }
            className={`mySwiper my-16 mx-auto`}>
            <SwiperSlide>
              <div className="w-[90%] md:w-[60%] lg:w-[40%] xl:w-full overflow-hidden mx-auto">
                <img
                  src={car}
                  alt="car"
                  className="w-[75%] mx-auto md:w-full"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-[90%] md:w-[60%] lg:w-[40%] xl:w-full overflow-hidden mx-auto">
                <img
                  src={car}
                  alt="car"
                  className="w-[75%] mx-auto md:w-full"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-[90%] md:w-[60%] lg:w-[40%] xl:w-full overflow-hidden mx-auto">
                <img
                  src={car}
                  alt="car"
                  className="w-[75%] mx-auto md:w-full"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-[90%] md:w-[60%] lg:w-[40%] xl:w-full overflow-hidden mx-auto">
                <img
                  src={car}
                  alt="car"
                  className="w-[75%] mx-auto md:w-full"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-[90%] md:w-[60%] lg:w-[40%] xl:w-full overflow-hidden mx-auto">
                <img
                  src={car}
                  alt="car"
                  className="w-[75%] mx-auto md:w-full"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-[90%] md:w-[60%] lg:w-[40%] xl:w-full overflow-hidden mx-auto">
                <img
                  src={car}
                  alt="car"
                  className="w-[75%] mx-auto md:w-full"
                />
              </div>
            </SwiperSlide>
            <img
              className="absolute left-[3.7rem] h-[62%] w-[70%] z-10 top-0 sm:w-[70%] sm:left-[3.6rem] sm:h-[63%] md:left-[9.8rem] md:h-[80%] md:w-[61%] lg:w-[40%] lg:h-[72%]  xl:w-[30%] xl:h-[68%] md:top-[0em] lg:left-1/2 lg:-translate-x-1/2"
              src={mac}
              alt="macbook"
            />
            <img
              className="absolute z-10 top-0 left-10 w-[80%] sm:top-[4rem] sm:w-[80%] sm:left-[2.4rem] sm:h-[75%] md:left-[5.2rem] md:h-[85%] md:w-[78%] lg:w-[55%] lg:h-[75%] xl:top-28  xl:w-[38%] xl:h-[70%] md:top-[8em] lg:left-1/2 lg:top-[7.5rem] lg:-translate-x-1/2"
              src={macKey}
              alt="macbook"
            />
          </Swiper>
        </div>
      </div>
    </section>
  );
}
