import mac from "../../assets/Macbook2.webp";
import pop from "../../assets/popMac.webp";
import disp from "../../assets/displayeMac.webp";
import { useRef } from "react";
import { useInView } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useMediaQuery } from "react-responsive";

export default function CarHistory() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const isExtraMiniWidth = useMediaQuery({ query: "(min-width: 280px)" });
  const isMidWidth = useMediaQuery({ query: "(min-width: 768px)" });
  const isLargeWidth = useMediaQuery({ query: "(min-width: 1024px)" });

  return (
    <section className="h-fit max-h-[100vh] text-plack max-w-[1520px] mx-auto flex items-center overflow-hidden">
      <div className="max-w-8xl py-20 relative mx-auto grid md:flex">
        <div className="text-center py-10 block md:hidden">
          <span className="text-bluePirelly font-semibold text-sm">
            how customers see car history?
          </span>
          <h2 className="font-bold w-[90%] mx-auto sm:mx-0  md:w-[450px] py-5 text-2xl md:text-3xl lg:text-4xl">
            Elevate Trust and credibility of your dealership
          </h2>
        </div>
        <div
          ref={ref}
          style={
            isLargeWidth
              ? {
                  left: isInView ? "0" : "-20em",
                  transition: "ease-in-out 1s",
                }
              : isMidWidth
              ? {
                  left: isInView ? "15%" : "-20em",
                  transition: "ease-in-out 1s",
                }
              : isExtraMiniWidth
              ? {
                  left: isInView ? "15%" : "-20em",
                  transition: "ease-in-out 1s",
                }
              : {}
          }
          className="relative w-[80%] mr-auto md:w-full">
          <div>
            <LazyLoadImage
              effect="blur"
              className="relative -left-40 w-[800px]"
              src={mac}
              alt="macbook"
            />
          </div>
          <div className="absolute top-2.5 -left-[9.9rem] sm:-left-[156px] lg:top-[22px] sm:top-4 md:top-2.5 w-[75%] lg:w-[75%] z-20">
            <LazyLoadImage
              effect="blur"
              width={"100%"}
              src={disp}
              alt="display mac"
            />
          </div>
          <div className="relative w-[50%] -top-[90%] -right-[10%] z-30 sm:-top-96 sm:-right-20 sm:w-[50%] md:w-[60%] lg:w-full md:-top-64 lg:-top-[30rem] lg:-right-44">
            <LazyLoadImage effect="blur" src={pop} alt="pop" />
          </div>
        </div>
        <div
          ref={ref}
          style={{
            left: isInView ? "0" : "20em",
            transition: "ease-in-out 1s",
          }}
          className="relative w-full text-center md:text-start">
          <span className="text-bluePirelly font-semibold text-sm md:block hidden">
            how customers see car history?
          </span>
          <h2 className="font-bold w-[90%] mx-auto sm:mx-0  md:w-[450px] py-5 text-2xl md:text-3xl lg:text-4xl md:block hidden">
            Elevate Trust and credibility of your dealership
          </h2>
          <p className="font-normal w-[80%] mx-auto md:mx-0 md:w-[400px] pb-16">
            Empower your customers with transparency through our unique vehicle
            history reporting feature. With our platform, you can effortlessly
            showcase the complete history of each vehicle, providing potential
            buyers with a comprehensive view of its journey.
          </p>
        </div>
      </div>
    </section>
  );
}
