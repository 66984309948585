import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/WeDryve.1080-PNG.webp";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="text-plack overflow-hidden">
      <div className="lg:p-4 xl:p-16">
        <div className="grid p-5 justify-center max-w-[1520px] md:h-full items-center mx-auto lg:grid sm:justify-center sm:p-5 xl:flex xl:justify-between xl:gap-4 gap-10 sm:gap-16">
          <div className="text-center xl:text-start w-full xl:w-2/5">
            <div className="relative h-28">
              <Link to="/">
                <img
                  src={logo}
                  className="w-28 absolute xl:left-0 left-1/2 xl:translate-x-0 -translate-x-1/2"
                  alt="logo"
                />
              </Link>
            </div>
            <p className="w-[70%] md:w-[80%] mx-auto xl:mx-0 text-xs sm:text-base font-medium my-4">
              The future is now. Discover a new era of car sales with We Dryve.
            </p>
          </div>
          <div className="grid w-full lg:text-start text-center justify-center md:grid-cols-3 lg:flex gap-8">
            <div className="text-xs w-full sm:text-base font-medium my-4 grid sm:h-[100px]">
              <h2 className="text-base sm:text-xl font-bold">Products</h2>
              <ul className="grid gap-3 mt-5">
                <li className="cursor-pointer hover:text-bluePirelly duration-200">
                  <Link to="/imaging">Virtual Walk</Link>
                </li>
                <li className="cursor-pointer hover:text-bluePirelly duration-200">
                  <Link to="/aichat">Ai Chat</Link>
                </li>
                {/* <li className="cursor-pointer hover:text-bluePirelly duration-200">
                  <Link to="/">Digital Retail</Link>
                </li> */}
              </ul>
            </div>
            <div className="w-full text-xs sm:text-base font-medium my-4 grid text-center lg:text-start sm:h-full">
              <h2 className="text-base sm:text-xl font-bold">Company</h2>
              <ul className="grid gap-3 mt-5">
                <li className="cursor-pointer hover:text-bluePirelly duration-200">
                  <Link to="/company/about">About Us</Link>{" "}
                </li>
                <li className="cursor-pointer hover:text-bluePirelly duration-200">
                  <Link to="/company/contact">Contact Us</Link>
                </li>
                <li className="cursor-pointer hover:text-bluePirelly duration-200">
                  <Link to="/company/privacy-policy">Privacy Policy</Link>
                </li>
                <li className="cursor-pointer hover:text-bluePirelly duration-200">
                  <Link to="/company/terms">Terms Of Service</Link>
                </li>
              </ul>
            </div>
            <div className="w-full grid text-xs sm:text-base font-medium my-4 sm:h-full">
              <h2 className="text-base sm:text-xl font-bold">Let's Talk</h2>
              <ul className="grid gap-2 mb-10 sm:mb-0">
                <li className="cursor-pointer hover:text-bluePirelly duration-200 flex mt-5 sm:mt-0">
                  <FontAwesomeIcon className="pr-2" icon={faEnvelope} />
                  info@WeDryve.com
                </li>
                <li className="cursor-pointer hover:text-bluePirelly duration-200 flex">
                  <FontAwesomeIcon
                    className="pr-2 block sm:inline"
                    icon={faPhone}
                  />
                  {"(888) 288-8856"}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center w-full max-w-[1520px] overflow-hidden mx-auto h-14 border-t-2 border-black/10">
        <p className="py-5 sm:pt-3 text-xs sm:text-sm lg:text-base">
          © 2023 WeDryve All Rights Reserved
        </p>
      </div>
    </footer>
  );
}

export default Footer;
