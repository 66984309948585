import { Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./components/homePage/HomePage";
import ImagingPage from "./components/imagingPage/ImagingPage";
import Nav from "./components/Nav";
import AboutUs from "./components/company/AboutUs";
import TremsOfServise from "./components/company/TremsOfServise";
import ContactUs from "./components/company/ContactUs";
import PrivayPolicy from "./components/company/PrivacyPolicy";
// import Tutorial from "./components/tutorial/Tutorial";
import AiChatPage from "./components/aiChatBot/AiChatPage";
import { useEffect, useRef } from "react";
import NotFound from "./components/NotFound";
import MiniNav from "./components/MiniNav";
import { useMediaQuery } from "react-responsive";
import ReactGA from "react-ga4";

const MEASUREMENT = "G-4WKFKZQ0HY";

ReactGA.initialize(MEASUREMENT);

// show the component from the top when change pages
function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.send({
      hitType: "pageview",
      page: document.location.pathname + document.location.search,
      title: document.location.pathname,
    });
  }, [pathname]);
}

function App() {
  const isMidWidth = useMediaQuery({ query: "(min-width: 300px)" });
  const isLargeWidth = useMediaQuery({ query: "(min-width: 970px)" });
  useScrollToTop();

  const sectionRef = useRef(null);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {isLargeWidth ? (
        <Nav scrollToSection={scrollToSection} />
      ) : isMidWidth ? (
        <MiniNav scrollToSection={scrollToSection} />
      ) : (
        ""
      )}
      <Routes>
        <Route
          index
          path="/"
          element={
            <HomePage
              scrollToSection={scrollToSection}
              sectionRef={sectionRef}
            />
          }
        />
        <Route
          path="/imaging"
          element={
            <ImagingPage
              scrollToSection={scrollToSection}
              sectionRef={sectionRef}
            />
          }
        />
        <Route
          path="/aichat"
          element={
            <AiChatPage
              scrollToSection={scrollToSection}
              sectionRef={sectionRef}
            />
          }
        />
        {/* <Route path="/tutorial" element={<Tutorial />} /> */}
        <Route path="/company/contact" element={<ContactUs />} />
        <Route path="/company/about" element={<AboutUs />} />
        <Route path="/company/terms" element={<TremsOfServise />} />
        <Route path="/company/privacy-policy" element={<PrivayPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
