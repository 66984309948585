import { useState } from "react";
import chatPhoto from "../../assets/chaAi.webp";
import chatPhoto2 from "../../assets/befor.webp";
import chatPhoto3 from "../../assets/chatphoto3.webp";
import iconChat from "../../assets/iconChat2.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useRef } from "react";
import { useInView } from "framer-motion";
// import { useMediaQuery } from "react-responsive";

export default function Unique() {
  const ref = useRef(null);
  const isInVeiw = useInView(ref, { once: true });

  // const isExtraMiniWidth = useMediaQuery({ query: "(min-width: 280px)" });
  // const isMidWidth = useMediaQuery({ query: "(min-width: 768px)" });

  const [condition, setCondition] = useState(1);

  return (
    <section>
      <div className="grid text-plack w-fit lg:w-full  lg:flex py-20 px-0 sm:px-4 overflow-hidden items-center max-w-7xl mx-auto relative">
        <div className="grid text-center justify-center w-fit lg:w-1/2 gap-5 items-center relative">
          <div className="w-full sm:w-[460px] relative mx-auto">
            <div className="flex w-full font-semibold justify-center text-zinc-300 gap-16 py-3">
              <h2
                onClick={() => setCondition(1)}
                style={{
                  color: condition === 1 ? "#07A4FC" : "#D9D9D9",
                  borderBottom: condition === 1 ? "4px solid #07A4FC" : "none",
                }}
                className="cursor-pointer text-sm sm:text-base pb-5 sm:pb-4 roudned-xl">
                chat
              </h2>
              <h2
                onClick={() => setCondition(2)}
                style={{
                  color: condition === 2 ? "#07A4FC" : "#D9D9D9",
                  borderBottom: condition === 2 ? "4px solid #07A4FC" : "none",
                }}
                className="cursor-pointer text-sm sm:text-base pb-5 sm:pb-4 roudned-xl">
                search engine
              </h2>
              <h2
                onClick={() => setCondition(3)}
                style={{
                  color: condition === 3 ? "#07A4FC" : "#D9D9D9",
                  borderBottom: condition === 3 ? "4px solid #07A4FC" : "none",
                }}
                className="cursor-pointer text-sm sm:text-base pb-5 sm:pb-4 roudned-xl">
                appointment
              </h2>
            </div>
            <div className="rounded-xl absolute top-[3.25rem] sm:top-[3.2rem] left-1/2 -translate-x-1/2 sm:translate-x-0 w-[360px] sm:w-[86%] sm:left-8 h-1 bg-black/20"></div>
          </div>
          <div className="text-center w-fit mx-auto">
            <h2 className="text-2xl py-5 sm:text-4xl font-bold">
              Fast, Smart, Professional conversation starts here{" "}
            </h2>
            <p className="text-sm w-[350px] mx-auto text-slate-600">
              Unlock Intelligent Conversations with Our AI-Powered Chat Solution
            </p>
          </div>
        </div>
        <div className="relative w-10 sm:w-24 hidden sm:block">
          <div className="absolute z-20 -rotate-6 w-10 sm:w-full left-10 -bottom-[25rem] lg:-bottom-32">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="116"
              height="121"
              viewBox="0 0 116 121"
              fill="none">
              <path
                d="M2.254 85.5313C23.3133 34.2709 66.9512 27.9635 104.35 38.8284"
                stroke="black"
                stroke-width="2.7411"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M100.811 46.3238L111.982 42.2602L104.793 29.8683"
                stroke="black"
                stroke-width="2.7411"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <img
            className="absolute w-16 -bottom-[28rem] lg:-bottom-44"
            src={iconChat}
            alt="icon"
          />
        </div>
        <div className="relative py-24 h-[450px] w-fit lg:w-1/2 mx-auto">
          <div
            ref={ref}
            style={{
              right: isInVeiw ? "50%" : "-50px",
              opacity: isInVeiw ? "1" : "0",
              transition: "ease-in-out 2.5s",
            }}
            className="w-[380px] sm:w-[500px] absolute translate-x-1/2  flex items-center justify-end">
            <LazyLoadImage
              style={{
                display: condition === 1 ? "block" : "none",
              }}
              effect="blur"
              src={chatPhoto}
              alt="screan"
              className="w-[50%] sm:w-[60%] mx-auto lg:w-3/5"
            />
            <LazyLoadImage
              style={{
                display: condition === 2 ? "block" : "none",
              }}
              effect="blur"
              src={chatPhoto2}
              alt="screan"
              className="w-[70%] sm:w-[80%] pt-10 mx-auto lg:w-[90%]"
            />
            <LazyLoadImage
              style={{
                display: condition === 3 ? "block" : "none",
              }}
              effect="blur"
              src={chatPhoto3}
              alt="screan"
              className="w-[50%] sm:w-[60%] mx-auto  lg:w-3/5"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
