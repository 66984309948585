import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper/modules";
import "./Cardbg.css";
import person from "../../assets/coment-1.webp";
import person2 from "../../assets/coment-2.webp";
import person3 from "../../assets/coment-3.webp";
import person4 from "../../assets/person5.png";
import person5 from "../../assets/person6.png";
import { useMediaQuery } from "react-responsive";

export default function Cards() {
  const isMidMaxWidth = useMediaQuery({ query: "(max-width: 768px)" });
  const isMidWidth = useMediaQuery({ query: "(min-width: 768px)" });
  const isLargeWidth = useMediaQuery({ query: "(min-width: 1024px)" });
  const isExtraWidth = useMediaQuery({ query: "(min-width: 1280px)" });

  return (
    <section className="bg-[#0da0f3] overflow-hidden h-fit max-h-[100vh] mx-auto flex items-center text-white">
      <div className="bgCards max-w-8xl mx-auto">
        <div className="max-w-7xl py-16 mx-auto text-center">
          <div className="py-16">
            <h4 className="font-semibold">customer review</h4>
            <h2 className="text-3xl lg:text-4xl pt-5 font-bold">
              Listen To What Our Customers Say
            </h2>
          </div>
          <div>
            <Swiper
              spaceBetween={0}
              loop={true}
              pagination={{
                clickable: true,
              }}
              navigation={isMidMaxWidth ? false : true}
              modules={[Pagination, Navigation]}
              className="mySwiper relative"
              style={
                isExtraWidth
                  ? {
                      width: "1280px",
                    }
                  : isLargeWidth
                  ? {
                      width: "1000px",
                    }
                  : isMidWidth
                  ? {
                      width: "768px",
                    }
                  : {}
              }>
              <SwiperSlide>
                <div className="bg-white shadowDef z-30 grid w-[90%] h-[80%] sm:w-[550px] rounded-lg mx-auto text-black px-5 sm:px-16 py-12">
                  <p className="text-sm sm:text-base">
                    Image 360 turned our car listings into virtual showrooms.
                    Customers love the detailed views, and it's definitely
                    boosted our online presence.
                  </p>
                  <div className="flex items-center justify-center pt-5">
                    <img src={person} alt="userphoto" />
                    <div className="text-start ml-4">
                      <h3 className="font-semibold text-sm sm:text-base">
                        Car Dealer
                      </h3>
                      <span className="text-zinc-500 text-xs sm:text-sm">
                        Joined August 2022
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <div>
                <div className="bg-white blur-[1px] shadowDef absolute top-14 sm:top-6 h-[75%] left-1/2 -translate-x-1/2 grid w-[75%] sm:w-[450px] mx-auto sm:h-[280px] rounded-lg text-black px-16 py-12">
                  {/* <p>
                    With WeDryve, my level of work went up several levels and my
                    sales increased dramatically!
                  </p> */}
                  {/* <div className="flex items-center justify-center pt-5">
                    <img src={avatar} alt="userphoto" />
                    <div className="text-start ml-4">
                      <h3 className="font-semibold">mike ross</h3>
                      <span className="text-sm text-zinc-500">
                        car dealership
                      </span>
                    </div>
                  </div> */}
                </div>
                <div className="bg-white blur-[1px] shadowDef absolute top-0 left-1/2 h-[85%] -translate-x-1/2 grid w-[80%] sm:w-[490px] rounded-lg text-black px-16 py-12">
                  {/* <p>
                    With WeDryve, my level of work went up several levels and my
                    sales increased dramatically!
                  </p>
                  <div className="flex items-center justify-center pt-5">
                    <img src={avatar} alt="userphoto" />
                    <div className="text-start ml-4">
                      <h3 className="font-semibold">mike ross</h3>
                      <span className="text-sm text-zinc-500">
                        car dealership
                      </span>
                    </div>
                  </div> */}
                </div>
              </div>
              <SwiperSlide>
                <div className="bg-white shadowDef z-30 grid w-[90%] h-[80%] sm:w-[550px] rounded-lg mx-auto text-black px-5 sm:px-16 py-12">
                  <p className="text-sm sm:text-base">
                    This technology is a game-changer for showcasing our
                    inventory. It's user-friendly, and the results speak for
                    themselves.
                  </p>
                  <div className="flex items-center justify-center pt-5">
                    <img src={person2} alt="userphoto" />
                    <div className="text-start ml-4">
                      <h3 className="font-semibold text-sm sm:text-base">
                        Auto Deal
                      </h3>
                      <span className="text-zinc-500 text-xs sm:text-sm">
                        Joined August 2022
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-white shadowDef z-30 grid w-[90%] h-[80%] sm:w-[550px] rounded-lg mx-auto text-black px-5 sm:px-16 py-12">
                  <p className="text-sm sm:text-base">
                    Image 360 has made our website stand out. Customers can
                    truly experience the cars before stepping into the
                    dealership.
                  </p>
                  <div className="flex items-center justify-center pt-5">
                    <img src={person3} alt="userphoto" />
                    <div className="text-start ml-4">
                      <h3 className="font-semibold text-sm sm:text-base">
                        mike ross
                      </h3>
                      <span className="text-zinc-500 text-xs sm:text-sm">
                        Golden Auto Store
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-white shadowDef z-30 grid w-[90%] h-[80%] sm:w-[550px] rounded-lg mx-auto text-black px-5 sm:px-16 py-12">
                  <p className="text-sm sm:text-base">
                    Our sales team loves Image 360. It helps us close deals
                    faster by providing customers with a 360-degree view of the
                    cars they're interested in.
                  </p>
                  <div className="flex items-center justify-center pt-5">
                    <img src={person4} alt="userphoto" />
                    <div className="text-start ml-4">
                      <h3 className="font-semibold text-sm sm:text-base">
                        Drive Motor
                      </h3>
                      <span className="text-zinc-500 text-xs sm:text-sm">
                        Joined August 2022
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-white shadowDef z-30 grid w-[90%] h-[80%] sm:w-[550px] rounded-lg mx-auto text-black px-5 sm:px-16 py-12">
                  <p className="text-sm sm:text-base">
                    Image 360 is a must-have for modern dealerships. It's easy
                    to use and sets us apart from the competition.
                  </p>
                  <div className="flex items-center justify-center pt-5">
                    <img src={person5} alt="userphoto" />
                    <div className="text-start ml-4">
                      <h3 className="font-semibold text-sm sm:text-base">
                        MNR Auto Sales
                      </h3>
                      <span className="text-zinc-500 text-xs sm:text-sm">
                        Joined August 2022
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}
