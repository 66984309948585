import "./Question.css";
import { useRef } from "react";
import { useInView } from "framer-motion";

export default function Question() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <section className="bg-bluePirelly text-plack h-fit max-h-[100vh] mx-auto flex items-center">
      <div
        ref={ref}
        style={{
          opacity: isInView ? "1" : "0",
          transition: "ease-in-out 1s",
        }}
        className="max-w-5xl py-32 CircleBg text-white mx-auto">
        <div className="relative text-center">
          <span>i have a question..</span>
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold py-10">
            Why should I have ai chatbot?
          </h2>
          <p className="sm:text-2xl md:text-4xl text-slate-200 leading-[3.5rem] w-4/5 mx-auto">
            Embracing AI chatbots brings a host of advantages to dealerships,
            transforming customer interactions and driving business growth.
            Enstant engagement | 24/7 availability | Efficiency boost | Data
            Insight | Trust building | Global reach with limited language
            barrier.
          </p>
        </div>
      </div>
    </section>
  );
}
