import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import { useInView } from "framer-motion";
import bg from "../../assets/retailbg.webp";
import pic from "../../assets/retail.webp";
import pic2 from "../../assets/retial2.webp";
import Countdown from "react-countdown";
import { useMediaQuery } from "react-responsive";

export default function DigitalRetail() {
  const ref = useRef(null);
  const inInView = useInView(ref, { once: true });

  const isMiniWidth = useMediaQuery({ query: "(min-width: 640px)" });
  const isMidWidth = useMediaQuery({ query: "(min-width: 768px)" });
  const isLargeWidth = useMediaQuery({ query: "(min-width: 1024px)" });
  const isExtraWidth = useMediaQuery({ query: "(min-width: 1280px)" });

  const currentDate = new Date();
  const targetDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 20, // Add 20 days
    currentDate.getHours() + 9, // Add 19 hours
    currentDate.getMinutes() + 19 // Add 10 minutes
  );

  const rendererTime = ({ days, hours, minutes }) => {
    return (
      <div className="flex w-[90%] text-plack mx-auto sm:mx-auto lg:mx-0 xl:mx-0 text-2xl font-bold gap-5 justify-center text-center py-10 sm:py-10 lg:py-10 xl:py-28 items-center">
        <p className="grid items-center justify-center">
          <h4 className="border-2 w-[80px] h-[80px] flex items-center rounded-xl px-5 border-zinc-200">
            {days}
          </h4>
          <h4 className="text-sm pt-5">Day</h4>
        </p>
        <p className="grid">
          <h4 className="border-2 w-[80px] h-[80px] flex items-center rounded-xl px-5 border-zinc-200">
            0{hours}
          </h4>
          <h4 className="text-sm pt-5">Hours</h4>
        </p>
        <p className="grid">
          <h4 className="border-2 w-[80px] h-[80px] flex items-center rounded-xl px-5 border-zinc-200">
            {minutes}
          </h4>
          <h4 className="text-sm pt-5">Minute</h4>
        </p>
      </div>
    );
  };

  return (
    <section>
      <div
        ref={ref}
        className="max-w-7xl py-16 overflow-hidden sm:block lg:flex items-center gap-10 sm:py-28 lg:py-16 lg:px-4 mx-auto">
        <div className="w-[350px] mx-auto sm:mx-0 sm:w-full overflow-hidden lg:w-1/2 relative py-24 ">
          <div className="absolute w-full lg:hidden top-0 text-center left-1/2 -translate-x-1/2">
            <span className="text-perPirelly font-semibold text-lg">
              We made it comprehensive
            </span>
            <h2 className="lg:text-4xl xl:text-5xl mt-3 font-bold">
              Digital Retail
            </h2>
          </div>
          <img
            className="sm:w-[60%] hidden sm:block sm:relative sm:left-1/2 sm:-translate-x-1/2 lg:w-full"
            src={bg}
            alt="bachground gray curve"
          />

          <img
            style={
              isExtraWidth
                ? {
                    right: inInView ? "0%" : "10rem",
                    transition: "ease-in-out 1s",
                  }
                : isLargeWidth
                ? {
                    right: inInView ? "10%" : "10rem",
                    transition: "ease-in-out 1s",
                  }
                : isMidWidth
                ? {
                    right: inInView ? "10%" : "10rem",
                    transition: "ease-in-out 1s",
                  }
                : isMiniWidth
                ? {
                    left: inInView ? "30%" : "10rem",
                    transition: "ease-in-out 1s",
                  }
                : isMiniWidth
                ? {
                    opacity: inInView ? "100%" : "0%",
                    transition: "ease-in-out 1s",
                  }
                : {}
            }
            src={pic2}
            className="absolute w-[85%] right-0 lg:left-32 sm:w-[50%] lg:w-[80%] top-20 sm:top-10"
            alt="bachground gray curve"
          />
          <img
            style={
              isExtraWidth
                ? {
                    left: inInView ? "0%" : "10rem",
                    transition: "ease-in-out 1s",
                  }
                : isLargeWidth
                ? {
                    left: inInView ? "10%" : "10rem",
                    transition: "ease-in-out 1s",
                  }
                : isMidWidth
                ? {
                    left: inInView ? "10%" : "10rem",
                    transition: "ease-in-out 1s",
                  }
                : isMiniWidth
                ? {
                    left: inInView ? "10%" : "10rem",
                    transition: "ease-in-out 1s",
                  }
                : isMiniWidth
                ? {
                    opacity: inInView ? "100%" : "0%",
                    transition: "ease-in-out 1s",
                  }
                : {}
            }
            src={pic}
            className="relative sm:absolute w-[85%] -left-2 sm:w-[50%] lg:w-[80%] top-10 sm:top-44"
            alt="bachground gray curve"
          />
        </div>
        <div className="w-full h-full gap-3 text-center sm:w-full sm:h-full sm:text-center lg:text-start xl:text-start lg:w-1/2 grid lg:gap-5 xl:gap-5 lg:h-[500px]">
          <div className="hidden lg:block">
            <span className="text-perPirelly font-semibold text-lg">
              We made it comprehensive
            </span>
            <h2 className="lg:text-4xl xl:text-5xl mt-3 font-bold">
              Digital Retail
            </h2>
          </div>
          <p className="font-normal w-[80%] mx-auto sm:w-[80%] sm:mx-auto lg:mx-0 lg:w-[90%]">
            Welcome to the future of car buying. Our Digital Retail platform
            empowers our dealerships to offer a 100% online, hassle-free car
            purchasing experience. Say goodbye to showroom limitations and hello
            to seamless, convenient, and efficient car transactions, all from
            the comfort of your screen
          </p>
          <p className="grid text-sm sm:grid sm:w-full lg:flex sm:py-5 lg:py-0 lg:w-[90%] sm:gap-5 sm:justify-center lg:justify-between">
            <span>
              <FontAwesomeIcon
                className="text-perPirelly pr-2"
                icon={faCheck}
              />
              Online Purchase Portal
            </span>
            <span>
              <FontAwesomeIcon
                className="text-perPirelly pr-2"
                icon={faCheck}
              />
              Digital Showroom
            </span>
            <span>
              <FontAwesomeIcon
                className="text-perPirelly pr-2"
                icon={faCheck}
              />
              Dealer Dashboard
            </span>
          </p>
          <button className="font-medium w-fit mx-auto sm:mx-auto sm:py-5 lg:mx-0 xl:mx-0 lg:py-0 xl:py-0 h-fit text-perPirelly">
            Coming Soon...
          </button>
          <Countdown
            className="w-[90%]"
            date={targetDate}
            renderer={rendererTime}
          />
        </div>
      </div>
    </section>
  );
}
