import screenChat from "../../assets/screenAichat.webp";
import avatar from "../../assets/avatarChat.webp";
import hand from "../../assets/hands.webp";
import bgHand from "../../assets/bgHand.webp";
import bgChat from "../../assets/bgChat.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useRef } from "react";
import { useInView } from "framer-motion";
import { useMediaQuery } from "react-responsive";

export default function Provide() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const isExtraMiniWidth = useMediaQuery({ query: "(min-width: 280px)" });
  const isMidWidth = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <section>
      <div className="max-w-[1520px] text-plack overflow-hidden mx-auto grid items-center py-16 text-center">
        <h2 className="text-3xl lg:text-4xl font-bold py-4">
          Our A.I is trained to sell!
        </h2>
        <p className="text-sm lg:text-2xl font-medium w-[60%] mx-auto">
          combining advanced technology and human-like persuasion to drive
          conversions and boost your business's success.
        </p>
        <div className="grid md:max-w-fit lg:w-full lg:flex my-16 justify-center items-center">
          <div className="w-full lg:w-1/2 relative">
            <div className="relative sm:w-[60%] md:w-[100%]  rounded-[50px] -left-14">
              <LazyLoadImage
                effect="blur"
                className="shadowDef rounded-[50px]"
                src={bgChat}
                alt="background"
              />
            </div>
            <div className="absolute w-[50%] sm:w-[60%] md:w-[500px] md:-left-10 lg:left-0 -bottom-10">
              <LazyLoadImage effect="blur" src={hand} alt="hand" />
            </div>
            <div className="absolute w-[20%] sm:w-[30%] md:w-[250px] md:-left-10 lg:left-0 -bottom-10">
              <LazyLoadImage effect="blur" src={bgHand} alt="background" />
            </div>
            <div
              ref={ref}
              style={{
                opacity: isInView ? "1" : "0",
                right: isInView ? "2.5rem" : "10rem",
                rotate: isInView ? "6deg" : "-6deg",
                transition: "ease-in-out 2s",
              }}
              className="absolute rotate-90 top-52 lg:rotate-6 lg:-top-20 left-72 md:right-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="201"
                height="224"
                viewBox="0 0 201 224"
                fill="none">
                <path
                  d="M2.2417 169.371C40.2307 67.9305 116.393 48.6751 181.25 63.2675"
                  stroke="#07A4FC"
                  stroke-width="2.7411"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M174.892 78.1819L194.452 68.5724L182.248 46.0763"
                  stroke="#07A4FC"
                  stroke-width="2.7411"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div
              ref={ref}
              style={
                isMidWidth
                  ? {
                      opacity: isInView ? "1" : "0",
                      left: isInView ? "7rem" : "0",
                      transition: "ease-in-out 2s",
                    }
                  : isExtraMiniWidth
                  ? {
                      opacity: isInView ? "1" : "0",
                      left: isInView ? "2rem" : "0",
                      transition: "ease-in-out 2s",
                    }
                  : {}
              }
              className="absolute left-28 top-20 sm:top-10 flex items-end gap-5">
              <img src={avatar} alt="" />
              <div>
                <p className="bg-white shadowDef h-fit px-4 py-2 font-medium text-xs rounded-bl-none rounded-3xl w-fit">
                  Can you send me the best ones?
                </p>
              </div>
            </div>
            <div
              ref={ref}
              style={
                isMidWidth
                  ? {
                      opacity: isInView ? "1" : "0",
                      left: isInView ? "7rem" : "0",
                      transition: "ease-in-out 1.5s",
                    }
                  : isExtraMiniWidth
                  ? {
                      opacity: isInView ? "1" : "0",
                      left: isInView ? "2rem" : "0",
                      transition: "ease-in-out 2s",
                    }
                  : {}
              }
              className="absolute top-36 sm:top-24 left-28 flex items-end gap-5">
              <img src={avatar} alt="" />
              <div>
                <p className="bg-white shadowDef h-fit px-4 py-2 rounded-bl-none rounded-3xl w-[260px] sm:w-[350px] text-start font-medium text-xs">
                  I Am Looking For A Car In The Price{" "}
                  {isExtraMiniWidth ? <br /> : ""} Range Of 30 Thousand Dollars
                </p>
              </div>
            </div>
          </div>
          <div className="w-1/2 h-fit">
            <div
              ref={ref}
              style={{
                opacity: isInView ? "1" : "0",
                right: isInView ? "2.5rem" : "10rem",
                rotate: isInView ? "0deg" : "-6deg",
                transition: "ease-in-out 2s",
              }}
              className="w-[400px] sm:w-[700px]">
              <LazyLoadImage effect="blur" src={screenChat} alt="chat tools" />
            </div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </section>
  );
}
