import { useRef } from "react";
import { useInView } from "framer-motion";
import around from "../../assets/360.webp";
import sc from "../../assets/sc.webp";
import majic from "../../assets/majic.webp";
import hot from "../../assets/hot.webp";

export default function WhyPirelly() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <section className="h-full bg-gradient-to-b from-[#DBDBDB] to-[#f4f4f5] sm:max-h-[100vh] text-plack flex items-center mx-auto overflow-hidden bg-zinc-100">
      <div className="max-w-7xl grid mx-auto">
        <div className="relative py-10 text-center">
          <span className="text-bluePirelly font-semibold text-sm">
            Why WeDryve ?
          </span>
          <h2 className="font-bold py-10 md:text-2xl md:w-[80%] mx-auto lg:mx-0 lg:w-full lg:text-4xl">
            Upgrade your dealership's visuals using our cutting edge technology
          </h2>
        </div>
        <div
          ref={ref}
          className="grid w-[90%] md:w-[90%] lg:w-full justify-center mx-auto grid-cols-1 lg:grid-cols-2 gap-x-5 lg:gap-x-10 lg:mx-auto">
          <div
            style={{
              left: isInView ? "0" : "50em",
              transition: "ease-in-out 0.9s",
            }}
            className=" w-full sm:w-fit grid justify-center text-center sm:text-start sm:flex sm:justify-between items-start cursor-pointer gap-3 relative p-5  md:p-5">
            <div className="flex items-center justify-center w-full sm:w-1/3">
              <img src={around} alt="icon" />
            </div>
            <div className="grid items-start justify-center text-center sm:justify-start w-full sm:w-2/3">
              <h3 className="font-semibold text-center sm:text-start">
                360 Turn table
              </h3>
              <p className="text-zinc-500 w-[100%] text-center sm:text-start sm:w-[90%] text-sm">
                With our state of the art technology we create stunning and
                interactive 360 imaging of your car.
              </p>
            </div>
          </div>
          <div
            style={{
              left: isInView ? "0" : "50em",
              transition: "ease-in-out 0.9s",
            }}
            className="w-full justify-center sm:w-fit grid sm:flex sm:justify-between items-start cursor-pointer gap-3 relative p-5  md:p-5">
            <div className="flex items-center justify-center w-full sm:w-1/3">
              <img src={sc} alt="icon" />
            </div>
            <div className="grid items-start justify-center sm:justify-start w-full sm:w-2/3">
              <h3 className="font-semibold text-center sm:text-start">
                A.I Background Removal
              </h3>
              <p className="text-zinc-500 w-[100%] text-center sm:text-start text-sm">
                Our A.I is Smart, Robust and accurate. We remove background
                noise from your images with 99% accuracy.
              </p>
            </div>
          </div>
          <div
            style={{
              left: isInView ? "0" : "50em",
              transition: "ease-in-out 0.9s",
            }}
            className=" w-full sm:w-fit grid justify-center sm:flex sm:justify-between items-start cursor-pointer gap-3 relative p-5  md:p-5">
            <div className="flex items-center justify-center w-full sm:w-1/3">
              <img src={hot} alt="icon" />
            </div>
            <div className="grid items-start justify-start w-full sm:w-2/3">
              <h3 className="font-semibold text-center sm:text-start">
                Hot spot/Damage tagging
              </h3>
              <p className="text-zinc-500 w-full sm:w-[90%] text-center sm:text-start text-sm">
                Build more brand awareness and trust by offering transparency
                and information to make car shopping a more enjoyable experience
                for your clients.
              </p>
            </div>
          </div>
          <div
            style={{
              left: isInView ? "0" : "50em",
              transition: "ease-in-out 0.9s",
            }}
            className=" w-full grid justify-center sm:w-full sm:flex sm:justify-between items-start cursor-pointer gap-3 relative p-5  md:p-5">
            <div className="flex items-center justify-center w-full sm:w-1/3">
              <img src={majic} alt="icon" />
            </div>
            <div className="grid items-start justify-start w-full sm:w-2/3">
              <h3 className="font-semibold text-center sm:text-start">
                Advance Dashboard
              </h3>
              <p className="text-zinc-500 text-center sm:text-start sm:w-[90%] text-sm">
                With our advance dashboard, we enable you to edit and enhance
                your visuals for a faster sale time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
