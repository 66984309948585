import personal from "../../assets/personal.webp";
import Footer from "../homePage/Footer";
import MapLocation from "./MapLocation";
import { useRef } from "react";
import { useInView } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function ContactUs() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const ref2 = useRef(null);
  const isInView2 = useInView(ref2, { once: true });
  const ref3 = useRef(null);
  const isInView3 = useInView(ref3, { once: true });

  return (
    <>
      <section
        ref={ref2}
        className="max-w-[1520px] text-plack overflow-hidden mx-auto">
        <div className="grid relative w-[100%] lg:w-full lg:flex items-center py-3 lg:py-24">
          <div
            style={{
              opacity: isInView2 ? "1" : "0",
              transition: "ease-in-out 1s",
            }}
            className="hidden text-black pl-0 text-center lg:text-start lg:pl-20 lg:grid gap-5 bottom-5 py-5 lg:py-0 z-10 lg:static lg:z-0 lg: lg:w-1/2">
            <h2 className="text-4xl font-bold">Contact Us</h2>
            <p className="font-medium mx-auto lg:mx-0 w-[80%] lg:w-[400px]">
              When, while lovely valley teems with vapour around meand meridian
              sun strikes the upper impenetrable foliage of my trees, and but a
              thousand.
            </p>
            <button className="px-5 py-3 bg-bluePirelly w-32 mx-auto lg:mx-0 text-white  rounded-lg font-medium">
              View More
            </button>
          </div>
          <div
            style={{
              opacity: isInView2 ? "1" : "0",
              transition: "all ease-in-out 1s",
            }}
            className="overflow-hidden relative rounded-bl-xl w-full lg:w-1/2">
            <div className="w-full relative">
              <LazyLoadImage
                effect="blur"
                width={"100%"}
                src={personal}
                alt="personel photo"
              />
              {/* section for resposive mid display or lower */}
              <div className="absolute grid bottom-2 rounded-bl-xl gap-3 py-3 bg-gradient-to-b from-white/0 to-black w-full left-1/2 -translate-x-1/2 lg:hidden">
                <h2 className="text-2xl sm:text-4xl py-10 font-bold text-white text-center">
                  Contact Us
                </h2>
              </div>
            </div>
            <div className="w-full grid gap-5 py-10 lg:hidden">
              <p className="font-medium text-sm sm:text-base mx-auto text-slate-500 text-center w-[80%]">
                When, while lovely valley teems with vapour around meand
                meridian sun strikes the upper impenetrable foliage of my trees,
                and but a thousand.
              </p>
              <button className="px-3 blueButton blueButton py-2 bg-bluePirelly mx-auto w-32  text-white rounded-lg font-medium">
                View More
              </button>
            </div>
          </div>
        </div>
        <div className="grid md:flex w-full overflow-hidden gap-4 px-4 md:px-10 items-center font-medium justify-center py-32">
          <div
            style={{
              opacity: isInView ? "1" : "0",
              top: isInView ? "0" : "-100px",
              transition: "ease-in-out 1s",
            }}
            className="grid relative rounded-xl shadowCompany w-[350px] md:w-[70%] h-[230px] items-center justify-center">
            <div className="w-fit py-6 h-fit mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none">
                <circle cx="30" cy="30" r="30" fill="#07A4FC" />
                <path
                  d="M30.385 18C25.7493 18 22 21.8121 22 26.5255C22 32.9196 30.385 42.3585 30.385 42.3585C30.385 42.3585 38.77 32.9196 38.77 26.5255C38.77 21.8121 35.0207 18 30.385 18ZM30.385 29.5703C29.5908 29.5703 28.8291 29.2495 28.2675 28.6785C27.7059 28.1075 27.3904 27.333 27.3904 26.5255C27.3904 25.7179 27.7059 24.9435 28.2675 24.3725C28.8291 23.8015 29.5908 23.4807 30.385 23.4807C31.1792 23.4807 31.9409 23.8015 32.5025 24.3725C33.0641 24.9435 33.3796 25.7179 33.3796 26.5255C33.3796 27.333 33.0641 28.1075 32.5025 28.6785C31.9409 29.2495 31.1792 29.5703 30.385 29.5703Z"
                  fill="white"
                />
              </svg>
            </div>
            <div>
              <p className="w-[230px] h-16 text-center text-sm mx-auto">
                3031 Tisch Way, San Jose, Ca, 95128
              </p>
            </div>
          </div>
          <div
            ref={ref}
            style={{
              opacity: isInView ? "1" : "0",
              top: isInView ? "0" : "-100px",
              transition: "ease-in-out 1.5s",
            }}
            className="grid relative rounded-xl shadowCompany w-[350px] md:w-[70%] h-[230px] items-center justify-center">
            <div className="w-fit py-6 h-fit mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none">
                <circle cx="30" cy="30" r="30" fill="#07A4FC" />
                <path
                  d="M38.3952 33.1317C37.1707 33.1317 35.9684 32.9401 34.8291 32.5635C34.2708 32.373 33.5845 32.5478 33.2438 32.8978L30.995 34.596C28.387 33.2034 26.7806 31.597 25.4074 29.0078L27.0551 26.8169C27.4832 26.3893 27.6367 25.7646 27.4527 25.1785C27.0746 24.0328 26.8825 22.8308 26.8825 21.6054C26.8826 20.7202 26.1626 20 25.2777 20H21.6048C20.7199 20 20 20.7202 20 21.6053C20 31.7517 28.252 40.0061 38.3952 40.0061C39.2801 40.0061 40 39.2859 40 38.4008V34.737C39.9999 33.8519 39.28 33.1317 38.3952 33.1317Z"
                  fill="white"
                />
              </svg>
            </div>
            <div>
              <p className="w-[230px] text-center text-sm h-10 mx-auto">
                {"(888) 288-8856"}
              </p>
            </div>
          </div>
          <div
            ref={ref}
            style={{
              opacity: isInView ? "1" : "0",
              top: isInView ? "0" : "-100px",
              transition: "ease-in-out 2s",
            }}
            className="grid relative rounded-xl shadowCompany w-[350px] md:w-[70%] h-[230px] items-center justify-center">
            <div className="w-fit h-fit mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none">
                <circle cx="30" cy="30" r="30" fill="#07A4FC" />
                <path
                  d="M19.4529 24.2047C22.4297 26.5059 27.6532 30.5544 29.1884 31.8167C29.3945 31.9871 29.6156 32.0737 29.8451 32.0737C30.0741 32.0737 30.2948 31.9879 30.5004 31.8183C32.037 30.5548 37.2604 26.5059 40.2373 24.2047C40.4227 24.0618 40.4509 23.8104 40.3009 23.6356C39.954 23.2316 39.4368 23 38.8826 23H20.8076C20.2534 23 19.7361 23.2316 19.3893 23.6356C19.2393 23.8104 19.2675 24.0618 19.4529 24.2047Z"
                  fill="white"
                />
                <path
                  d="M40.4279 25.4496C40.2677 25.3815 40.0793 25.4053 39.946 25.5092C36.6447 28.0637 32.4314 31.338 31.1057 32.4284C30.3617 33.0414 29.3291 33.0414 28.5833 32.4276C27.1704 31.2655 22.4389 27.594 19.744 25.5092C19.6098 25.4052 19.421 25.3823 19.2621 25.4495C19.1024 25.5173 19 25.6631 19 25.8238V36.1965C19 37.1064 19.8106 37.8464 20.8075 37.8464H38.8825C39.8794 37.8464 40.69 37.1064 40.69 36.1965V25.8238C40.69 25.6631 40.5876 25.5169 40.4279 25.4496Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="w-[230px] h-16 text-center text-sm mx-auto">
              <a className="block" href="pirelly@gmail.com">
                Sales@WeDryve.com
              </a>
              <a href="info@pirelly.com">Support@WeDryve.com</a>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto text-center">
          <div>
            <h2 className="md:text-3xl lg:text-4xl font-bold">
              Elevate your online store
            </h2>
            <p className="font-medium text-slate-500 w-[80%] mx-auto py-14">
              Elevate your online store by displaying your inventory online with
              the most engaging and powerful showroom to enable customers to
              easily search, identify, and purchase directly online with home
              delivery or dealership pickup.
            </p>
          </div>
        </div>
        <div className="grid py-20 lg:px-0 px-4 justify-center lg:flex items-center gap-5 max-w-7xl mx-auto">
          <div
            ref={ref3}
            style={{
              opacity: isInView3 ? "1" : "0",
              top: isInView3 ? "0" : "-100px",
              transition: "ease-in-out 1.5s",
            }}
            className="overflow-hidden rounded-lg flex-1 relative z-10 w-fit md:w-full md:mx-0 mx-auto">
            <MapLocation />
          </div>
          <div
            ref={ref3}
            style={{
              opacity: isInView3 ? "1" : "0",
              top: isInView3 ? "0" : "-100px",
              transition: "ease-in-out 1.5s",
            }}
            className="flex-1 relative rounded-lg shadowCompany">
            <form className="grid sm:justify-center p-5 sm:p-10 gap-10 items-center">
              <input
                className="bg-zinc-100 rounded-md h-[55px] w-full sm:w-[550px] placeholder:text-black px-6 py-2"
                type="text"
                placeholder="Enter your Name*"
              />
              <input
                className="bg-zinc-100 rounded-md h-[55px] w-full sm:w-[550px] placeholder:text-black px-6 py-2"
                type="email"
                placeholder="Enter your email*"
              />
              <textarea
                className="bg-zinc-100 rounded-md h-[150px] w-full sm:w-[550px] placeholder:text-black px-6 py-2"
                type="text"
                placeholder="Enter your message*"
              />
              <button className="px-5 blueButton py-4 bg-bluePirelly w-44 text-white  rounded-lg font-medium">
                Send Message
              </button>
            </form>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}
