import person from "../../assets/personWorkMac.webp";
import "./ManageProject.css";

export default function ManageProject() {
  return (
    <section>
      <div className="grid text-plack lg:flex items-center max-w-[1520px] mx-auto relative overflow-hidden">
        <div className="grid justify-center text-center lg:text-start w-full lg:w-1/2 gap-5 items-center relative">
          <span className="text-bluePirelly font-semibold">
            Manage your projects in the app
          </span>
          <h2 className="text-2xl px-2 sm:px-0 sm:text-3xl lg:text-4xl w-full sm:w-[450px] font-bold">
            Unlock Intelligent Conversations with Our AI-Powered Chat Solution{" "}
          </h2>
          <p className="text-sm w-[90%] mx-auto lg:mx-0 sm:w-[380px] text-slate-600">
            Elevate your dealership's customer service with AI-powered
            conversations that leave a lasting impression. Embrace the future of
            communication and connect with customers on a whole new level.
          </p>
        </div>
        <div className="w-full lg:w-1/2 relative flex items-center justify-end">
          <div className="bg-zinc-300 block px-4 py-2 text-xs sm:px-6 sm:py-4 sm:text-sm font-medium rounded-3xl rounded-br-none absolute top-16 left-[10%] sm:top-40 sm:left-[28%] lg:top-20 lg:left-0 xl:left-32 xl:top-44">
            Can You Send Me The Best Ones?
          </div>
          <div className="bg-zinc-300 px-6 py-4 text-sm font-medium relative left-[20%] md:left-[20%] lg:left-20 rounded-3xl rounded-br-none flex gap-1">
            <div className="w-1.5 h-1.5 rounded-full animateClass1"></div>
            <div className="w-1.5 h-1.5 rounded-full animateClass2"></div>
            <div className="w-1.5 h-1.5 rounded-full animateClass3"></div>
          </div>
          <img
            className="w-[50%] sm:w-1/2"
            src={person}
            alt="person working with labtop"
          />
        </div>
      </div>
    </section>
  );
}
