import phone from "../../assets/Phone.webp";
import progerss from "../../assets/progress.webp";
import { useRef } from "react";
import { useInView } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useMediaQuery } from "react-responsive";

export default function CreateYour() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const isExtraMiniWidth = useMediaQuery({ query: "(min-width: 280px)" });
  const isMidWidth = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <section
      ref={ref}
      className="max-w-[1520px] text-plack mx-auto flex items-center overflow-hidden">
      <div className="max-w-7xl py-20 mx-auto grid gap-10 lg:gap-20 lg:flex">
        <div className="relative lg:px-4 xl:px-0 py-0 lg:py-20 w-full text-center lg:text-start lg:w-3/6">
          <span className="text-bluePirelly font-semibold text-sm">
            fast but powerful!
          </span>
          <h2 className="font-bold w-[90%] mx-auto lg:mx-0 py-5 text-2xl lg:text-3xl xl:text-4xl">
            from start to finish in less than 5 minute
          </h2>
          <p className="font-normal hidden lg:block w-[80%] mx-auto lg:mx-0  lg:w-full pb-16">
            No complicated processes, no extensive training – just efficiency,
            precision, and speed at your fingertips. Transform the way you
            present cars with ease, and witness your project materialize in
            record time using our app.
          </p>
        </div>
        <div className="w-full lg:w-3/6 h-[150px] sm:h-[400px] relative">
          <div
            style={
              isMidWidth
                ? {
                    left: isInView ? "50%" : "50em",
                    transform: "translateX(-50%)",
                    transition: "ease-in-out 1s",
                  }
                : isExtraMiniWidth
                ? {
                    left: isInView ? "50%" : "50em",
                    transform: "translateX(-50%)",
                  }
                : {
                    right: isInView ? "0" : "-50em",
                    transition: "ease-in-out 1.5s",
                  }
            }
            className="relative w-[320px] sm:w-[80%] md:w-[60%] lg:w-[80%] top-0 lg:top-10 rounded-3xl">
            <LazyLoadImage effect="blur" src={phone} alt="cardemofeature" />
          </div>
          <div
            style={
              isMidWidth
                ? {
                    left: isInView ? "50%" : "50em",
                    transform: "translateX(-50%)",
                    transition: "ease-in-out 1s",
                  }
                : isExtraMiniWidth
                ? {
                    left: isInView ? "50%" : "50em",
                    transform: "translateX(-50%)",
                    transition: "ease-in-out 1s",
                  }
                : {
                    left: isInView ? "-5rem" : "50em",
                    transition: "ease-in-out 1s",
                  }
            }
            className="absolute w-[300px] sm:w-[80%] md:w-[80%] -bottom-5 sm:bottom-10 rounded-3xl">
            <LazyLoadImage
              effect="blur"
              width={"100%"}
              src={progerss}
              alt="cardemofeature"
            />
          </div>
        </div>
        <p className="font-normal py-5 text-center w-[80%] mx-auto lg:mx-0 block lg:hidden lg:w-full pb-16">
          No complicated processes, no extensive training – just efficiency,
          precision, and speed at your fingertips. Transform the way you present
          cars with ease, and witness your project materialize in record time
          using our app.
        </p>
      </div>
    </section>
  );
}
