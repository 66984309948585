import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./swiper-custom.css";
import { Pagination } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import person from "../../assets/coment-1.webp";
import person2 from "../../assets/coment-2.webp";
import person3 from "../../assets/coment-3.webp";
import person4 from "../../assets/person5.png";
import person5 from "../../assets/person6.png";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import "./Comments.css";

function Comments() {
  return (
    <section className="bg-whitePirelly text-plack flex items-center py-20 overflow-hidden">
      <div className="max-h-[100vh] w-full  max-w-[1520px] mx-auto">
        <div className="text-center">
          <h2 className="lg:text-4xl xl:text-5xl my-5 font-medium">
            Listen To What Our Customers Say
          </h2>
        </div>
        <div className="w-full my-16  mx-auto overflow-hidden">
          <Swiper
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              1100: {
                slidesPerView: 2.5,
              },
              800: {
                slidesPerView: 1.8,
              },
              500: {
                slidesPerView: 1.3,
              },
              300: {
                slidesPerView: 1.1,
              },
            }}
            slidesPerView={2.5}
            modules={[Pagination]}
            className="mySwiper sizeSwiper">
            <SwiperSlide className="p-14 cursor-pointer">
              <div className="grid gap-8 relative w-[300px] h-[250px] sm:w-[420px] sm:h-[240px] rounded-[40px] shadow-2xl shadowComment px-10 py-16 sm:py-10 sm:px-16">
                <FontAwesomeIcon
                  className="absolute text-4xl text-bluePirelly right-8 top-5"
                  icon={faQuoteLeft}
                />
                <p className="text-xs sm:text-base font-medium">
                  AI Chat has transformed how we interact with customers. It's
                  like having a knowledgeable assistant available 24/7
                </p>
                <div className="flex gap-5 items-center text-xs sm:text-sm">
                  <img className="w-12" src={person} alt="avatar" />
                  <div className="font-medium">
                    <h3>golden auto store</h3>
                    <span className="text-slate-500 text-xs">
                      joined august 2020
                    </span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="p-14 cursor-pointer">
              <div className=" grid gap-8 relative w-[300px] h-[250px] sm:w-[420px] sm:h-[240px] rounded-[40px] shadow-2xl shadowComment px-10 py-16 sm:py-10 sm:px-16">
                <FontAwesomeIcon
                  className="absolute text-4xl text-bluePirelly right-8 top-5"
                  icon={faQuoteLeft}
                />
                <p className="text-xs sm:text-base font-medium">
                  Our customers love the quick responses and accurate answers
                  from AI Chat. It's improved our customer service
                  significantly.
                </p>
                <div className="flex gap-5 items-center text-xs sm:text-sm">
                  <img className="w-12" src={person2} alt="avatar" />
                  <div className="font-medium">
                    <h3>Car Deal</h3>
                    <span className="text-slate-500 text-xs">
                      joined august 2020
                    </span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="p-14 cursor-pointer">
              <div className=" grid gap-8 relative w-[300px] h-[250px] sm:w-[420px] sm:h-[240px] rounded-[40px] shadow-2xl shadowComment px-10 py-16 sm:py-10 sm:px-16">
                <FontAwesomeIcon
                  className="absolute text-4xl text-bluePirelly right-8 top-5"
                  icon={faQuoteLeft}
                />
                <p className="text-xs sm:text-base font-medium">
                  AI Chat has boosted our efficiency. It handles routine
                  queries, allowing our team to focus on more complex tasks.
                </p>
                <div className="flex gap-5 items-center  text-sm">
                  <img className="w-12" src={person3} alt="avatar" />
                  <div className="font-medium">
                    <h3>MNR Auto Sale </h3>
                    <span className="text-slate-500">joined august 2020</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="p-14 cursor-pointer">
              <div className=" grid gap-8 relative w-[300px] h-[250px] sm:w-[420px] sm:h-[240px] rounded-[40px] shadow-2xl shadowComment px-10 py-16 sm:py-10 sm:px-16">
                <FontAwesomeIcon
                  className="absolute text-4xl text-bluePirelly right-8 top-5"
                  icon={faQuoteLeft}
                />
                <p className="text-xs sm:text-base font-medium">
                  The AI Chat integration was seamless, and it's made our
                  website more engaging. Customers appreciate the instant
                  support.
                </p>
                <div className="flex gap-5 items-center  text-sm">
                  <img className="w-12" src={person5} alt="avatar" />
                  <div className="font-medium">
                    <h3>A uto Deal</h3>
                    <span className="text-slate-500">joined august 2020</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="p-14 cursor-pointer">
              <div className=" grid gap-8 relative w-[300px] h-[250px] sm:w-[420px] sm:h-[240px] rounded-[40px] shadow-2xl shadowComment px-10 py-16 sm:py-10 sm:px-16">
                <FontAwesomeIcon
                  className="absolute text-4xl text-bluePirelly right-8 top-5"
                  icon={faQuoteLeft}
                />
                <p className="text-xs sm:text-base font-medium">
                  AI Chat has improved our conversion rates. Customers feel more
                  informed and confident when making a purchase decision.
                </p>
                <div className="flex gap-5 items-center  text-sm">
                  <img className="w-12" src={person4} alt="avatar" />
                  <div className="font-medium">
                    <h3>Drive Motor</h3>
                    <span className="text-slate-500">joined august 2020</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default Comments;
