import { faArrowRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/WeDryveR.1080.590-PNG.png";
import { useState } from "react";
import { Link } from "react-router-dom";

function Nav({ scrollToSection }) {
  const [active, setActive] = useState(null);

  //for changing color and drowp menu
  const handleClick = (itemNum) => {
    if (itemNum !== active) setActive(itemNum);
    else setActive(null);
  };

  return (
    <nav className="fixed w-full text-plack items-center gap-16 z-50 bg-white px-20 font-medium">
      <div className="flex max-w-7xl h-[70px] items-center mx-auto justify-center  gap-10 ">
        <div className="flex w-fit items-center">
          <Link className="w-24 h-12" to="/">
            <img className="w-full" src={logo} alt="LogoCompany" />
          </Link>
        </div>
        <ul className="flex gap-10">
          {/* <li className=" active:text-bluePirelly">
            <Link to="/" onClick={() => setActive(null)}>
              Home
            </Link>
          </li> */}
          <li>
            <div className="flex items-center">
              <h4
                style={{
                  color: active === 1 ? "#07A4FC" : "black",
                }}
                onClick={() => handleClick(1)}
                className="cursor-pointer">
                Products
              </h4>
              <div
                className="flex ml-2 items-center justify-center"
                style={{
                  rotate: active === 1 ? "0deg" : "-180deg",
                  color: active === 1 ? "#07A4FC" : "black",
                  transition: "ease-in-out 0.5s",
                }}>
                <FontAwesomeIcon className="text-xs" icon={faChevronDown} />
              </div>
            </div>
            <div
              style={{
                display: active === 1 ? "block" : "none",
                transition: "ease-in-out 0.5s",
              }}
              className="fixed left-0 top-[70px] w-full h-full bg-black/30 z-10"
              onClick={() => handleClick(1)}></div>
            <div
              style={{
                display: active === 1 ? "flex" : "none",
                transition: "ease-in-out 0.5s",
              }}
              className="flex fixed top-[70px] pb-8 pt-4 rounded-b-xl gap-20 items-start cursor-pointer z-10 bg-white w-4/5 left-1/2 px-8 -translate-x-1/2">
              <div className="flex group gap-5 w-1/2">
                <div>
                  <svg
                    width="48"
                    height="54"
                    viewBox="0 0 48 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      opacity="0.15"
                      d="M20.1202 1.03959C22.521 -0.346529 25.479 -0.346529 27.8798 1.03959L44.1202 10.416C46.521 11.8021 48 14.3638 48 17.136V35.8888C48 38.6611 46.521 41.2227 44.1202 42.6088L27.8798 51.9852C25.479 53.3713 22.521 53.3713 20.1202 51.9852L3.87979 42.6088C1.47897 41.2227 0 38.6611 0 35.8888V17.136C0 14.3638 1.47897 11.8021 3.87979 10.416L20.1202 1.03959Z"
                      fill="#07A4FC"
                    />
                    <path
                      d="M15.2275 25.6522C15.2477 25.5875 15.2567 25.52 15.2797 25.456L17.1565 20.2894C17.4016 19.619 17.8466 19.04 18.4313 18.6305C19.016 18.2211 19.7123 18.001 20.4261 18H26.2696C26.9835 18.001 27.6799 18.2211 28.2646 18.6307C28.8493 19.0403 29.2942 19.6195 29.5391 20.2901L31.4174 25.456C31.4403 25.52 31.4494 25.5875 31.4696 25.6522H15.2275ZM28.913 27.0435V28.4348C28.913 28.6193 28.8398 28.7962 28.7093 28.9267C28.5788 29.0571 28.4019 29.1304 28.2174 29.1304C28.0329 29.1304 27.856 29.0571 27.7255 28.9267C27.595 28.7962 27.5217 28.6193 27.5217 28.4348V27.0435H19.1739V28.4348C19.1739 28.6193 19.1006 28.7962 18.9702 28.9267C18.8397 29.0571 18.6628 29.1304 18.4783 29.1304C18.2938 29.1304 18.1168 29.0571 17.9864 28.9267C17.8559 28.7962 17.7826 28.6193 17.7826 28.4348V27.0435H15V28.4348C15.0009 29.1108 15.1988 29.7719 15.5694 30.3372C15.94 30.9026 16.4674 31.3477 17.087 31.6181V32.6087C17.087 32.9777 17.2335 33.3316 17.4945 33.5925C17.7554 33.8534 18.1093 34 18.4783 34C18.8473 34 19.2011 33.8534 19.4621 33.5925C19.723 33.3316 19.8696 32.9777 19.8696 32.6087V31.913H26.8261V32.6087C26.8261 32.9777 26.9727 33.3316 27.2336 33.5925C27.4945 33.8534 27.8484 34 28.2174 34C28.5864 34 28.9403 33.8534 29.2012 33.5925C29.4621 33.3316 29.6087 32.9777 29.6087 32.6087V31.6181C30.2283 31.3477 30.7556 30.9026 31.1263 30.3372C31.4969 29.7719 31.6948 29.1108 31.6957 28.4348V27.0435H28.913Z"
                      fill="#07A4FC"
                    />
                  </svg>
                </div>
                <div>
                  <Link
                    to="/imaging"
                    onClick={() => handleClick(0)}
                    className="font-semibold group-hover:text-bluePirelly">
                    Virtual Walk Around
                  </Link>
                  <p className="text-xs text-slate-600">
                    Changing the car buying experience with a 360 degree image
                  </p>
                </div>
                <FontAwesomeIcon
                  className="group-hover:text-bluePirelly"
                  icon={faArrowRight}
                />
              </div>
              <div className="flex group gap-5 w-1/2">
                <div>
                  <svg
                    width="48"
                    height="54"
                    viewBox="0 0 48 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      opacity="0.15"
                      d="M20.1202 1.03959C22.521 -0.346529 25.479 -0.346529 27.8798 1.03959L44.1202 10.416C46.521 11.8021 48 14.3638 48 17.136V35.8888C48 38.6611 46.521 41.2227 44.1202 42.6088L27.8798 51.9852C25.479 53.3713 22.521 53.3713 20.1202 51.9852L3.87979 42.6088C1.47897 41.2227 0 38.6611 0 35.8888V17.136C0 14.3638 1.47897 11.8021 3.87979 10.416L20.1202 1.03959Z"
                      fill="#07A4FC"
                    />
                    <path
                      d="M29.1016 20.8406C28.2571 20.1377 27.2761 19.6175 26.2205 19.3126C25.165 19.0076 24.0577 18.9246 22.9684 19.0688C20.9534 19.3306 19.1135 20.3494 17.8224 21.9184C16.5313 23.4874 15.8857 25.489 16.0167 27.5167C16.1476 29.5445 17.0454 31.4463 18.5277 32.8362C20.01 34.226 21.9657 34.9997 23.9976 35H28.6517C29.5353 34.9989 30.3824 34.6475 31.0072 34.0227C31.632 33.3978 31.9835 32.5507 31.9846 31.6671V26.5405V26.4985C31.914 25.4089 31.622 24.3452 31.1263 23.3724C30.6306 22.3995 29.9416 21.538 29.1016 20.8406ZM21.3193 23.6682H23.9856C24.1624 23.6682 24.332 23.7384 24.457 23.8634C24.582 23.9884 24.6522 24.158 24.6522 24.3348C24.6522 24.5115 24.582 24.6811 24.457 24.8061C24.332 24.9311 24.1624 25.0013 23.9856 25.0013H21.3193C21.1425 25.0013 20.973 24.9311 20.848 24.8061C20.723 24.6811 20.6528 24.5115 20.6528 24.3348C20.6528 24.158 20.723 23.9884 20.848 23.8634C20.973 23.7384 21.1425 23.6682 21.3193 23.6682ZM26.652 30.334H21.3193C21.1425 30.334 20.973 30.2637 20.848 30.1387C20.723 30.0137 20.6528 29.8442 20.6528 29.6674C20.6528 29.4906 20.723 29.321 20.848 29.196C20.973 29.071 21.1425 29.0008 21.3193 29.0008H26.652C26.8287 29.0008 26.9983 29.071 27.1233 29.196C27.2483 29.321 27.3185 29.4906 27.3185 29.6674C27.3185 29.8442 27.2483 30.0137 27.1233 30.1387C26.9983 30.2637 26.8287 30.334 26.652 30.334ZM26.652 27.6676H21.3193C21.1425 27.6676 20.973 27.5974 20.848 27.4724C20.723 27.3474 20.6528 27.1779 20.6528 27.0011C20.6528 26.8243 20.723 26.6547 20.848 26.5297C20.973 26.4047 21.1425 26.3345 21.3193 26.3345H26.652C26.8287 26.3345 26.9983 26.4047 27.1233 26.5297C27.2483 26.6547 27.3185 26.8243 27.3185 27.0011C27.3185 27.1779 27.2483 27.3474 27.1233 27.4724C26.9983 27.5974 26.8287 27.6676 26.652 27.6676Z"
                      fill="#07A4FC"
                    />
                  </svg>
                </div>
                <div>
                  <Link
                    to="/aichat"
                    onClick={() => handleClick(0)}
                    className="font-semibold group-hover:text-bluePirelly">
                    Ai Chat
                  </Link>
                  <p className="text-xs text-slate-600">
                    24-hour online support for your site without hiring an
                    employee
                  </p>
                </div>
                <FontAwesomeIcon
                  className="group-hover:text-bluePirelly"
                  icon={faArrowRight}
                />
              </div>
              {/* <div className="flex group gap-5">
                  <div>
                    <svg
                      width="48"
                      height="54"
                      viewBox="0 0 48 54"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        opacity="0.15"
                        d="M20.1202 1.03959C22.521 -0.346529 25.479 -0.346529 27.8798 1.03959L44.1202 10.416C46.521 11.8021 48 14.3638 48 17.136V35.8888C48 38.6611 46.521 41.2227 44.1202 42.6088L27.8798 51.9852C25.479 53.3713 22.521 53.3713 20.1202 51.9852L3.87979 42.6088C1.47897 41.2227 0 38.6611 0 35.8888V17.136C0 14.3638 1.47897 11.8021 3.87979 10.416L20.1202 1.03959Z"
                        fill="#07A4FC"
                      />
                      <path
                        d="M30.6828 24.5234L25.6235 19.5867C25.1841 19.1467 24.5521 18.9314 23.9381 19.0194L19.3462 19.6474C18.9815 19.6974 18.7262 20.0334 18.7762 20.3981C18.8262 20.7627 19.1615 21.0174 19.5275 20.9681L24.1201 20.3401C24.3241 20.3121 24.5348 20.3821 24.6875 20.5347L29.7475 25.4714C30.4441 26.168 30.5121 27.258 29.9628 28.036C29.8068 27.7247 29.6141 27.428 29.3555 27.1694L24.3721 22.228C23.9335 21.7894 23.3075 21.5781 22.6868 21.6607L18.0942 22.2887C17.7915 22.33 17.5555 22.572 17.5222 22.876L17.0122 27.4867C16.9455 28.094 17.1548 28.6894 17.5882 29.122L22.5321 34.024C23.1615 34.654 23.9981 35 24.8881 35H24.8995C25.7941 34.9973 26.6328 34.6453 27.2615 34.0087L29.3728 31.87C29.9908 31.244 30.3015 30.4307 30.3215 29.6114L30.7061 29.2274C31.9894 27.9274 31.9821 25.8194 30.6848 24.5227L30.6828 24.5234ZM20.3322 25.6674C19.9642 25.6674 19.6655 25.3687 19.6655 25.0007C19.6695 24.1287 20.9948 24.1294 20.9988 25.0007C20.9988 25.3687 20.7002 25.6674 20.3322 25.6674Z"
                        fill="#07A4FC"
                      />
                    </svg>
                  </div>
                  <div>
                    <Link
                      to="/"
                      onClick={() => handleClick(0)}
                      className="font-semibold group-hover:text-bluePirelly">
                      Digital Retail
                    </Link>
                    <p className="text-xs text-slate-600">
                      With a few clicks, have your own store website with the
                      best facilities
                    </p>
                  </div>
                  <FontAwesomeIcon
                    className="group-hover:text-bluePirelly"
                    icon={faArrowRight}
                  />
                </div> */}
            </div>
          </li>
          {/* <li className=" active:text-bluePirelly">
            <Link to="/tutorial">Tutorial</Link>
          </li> */}
          <li>
            <div className="flex items-center">
              <h4
                style={{
                  color: active === 2 ? "#07A4FC" : "black",
                }}
                onClick={() => handleClick(2)}
                className="cursor-pointer">
                Company
              </h4>
              <div
                className="flex ml-2 items-center justify-center"
                style={{
                  rotate: active === 2 ? "0deg" : "-180deg",
                  color: active === 2 ? "#07A4FC" : "black",
                  transition: "ease-in-out 0.5s",
                }}>
                <FontAwesomeIcon className="text-xs" icon={faChevronDown} />
              </div>
            </div>
            <div
              style={{
                display: active === 2 ? "block" : "none",
                transition: "ease-in-out 0.5s",
              }}
              className="fixed left-0 top-[70px] w-full h-full bg-black/30"
              onClick={() => handleClick(2)}></div>
            <div
              style={{
                display: active === 2 ? "block" : "none",
                transition: "ease-in-out 0.5s",
              }}
              onClick={() => handleClick(2)}
              className="bg-white top-[70px] fixed left-1/2 -translate-x-1/2 px-4 pb-8 pt-4 rounded-b-xl xl:w-fit lg:w-[600px] md:w-[530px] flex">
              <div className="grid gap-4">
                <div className="flex items-center gap-5">
                  <div className="flex justify-center items-center relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="54"
                      viewBox="0 0 48 54"
                      fill="none">
                      <path
                        opacity="0.15"
                        d="M20.1202 1.03959C22.521 -0.346529 25.479 -0.346529 27.8798 1.03959L44.1202 10.416C46.521 11.8021 48 14.3638 48 17.136V35.8888C48 38.6611 46.521 41.2227 44.1202 42.6088L27.8798 51.9852C25.479 53.3713 22.521 53.3713 20.1202 51.9852L3.87979 42.6088C1.47897 41.2227 0 38.6611 0 35.8888V17.136C0 14.3638 1.47897 11.8021 3.87979 10.416L20.1202 1.03959Z"
                        fill="#07A4FC"
                      />
                    </svg>
                    <svg
                      className="absolute inset-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <g clip-path="url(#clip0_2447_1825)">
                        <path
                          d="M7.33333 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 16H9.33333V2C9.33333 1.46957 9.12262 0.960859 8.74755 0.585786C8.37247 0.210714 7.86377 0 7.33333 0ZM4 12.6667H2V11.3333H4V12.6667ZM4 10H2V8.66667H4V10ZM4 7.33333H2V6H4V7.33333ZM4 4.66667H2V3.33333H4V4.66667ZM7.33333 12.6667H5.33333V11.3333H7.33333V12.6667ZM7.33333 10H5.33333V8.66667H7.33333V10ZM7.33333 7.33333H5.33333V6H7.33333V7.33333ZM7.33333 4.66667H5.33333V3.33333H7.33333V4.66667ZM14 3.33333H10.6667V16H16V5.33333C16 4.8029 15.7893 4.29419 15.4142 3.91912C15.0391 3.54405 14.5304 3.33333 14 3.33333ZM14 12.6667H12.6667V11.3333H14V12.6667ZM14 10H12.6667V8.66667H14V10ZM14 7.33333H12.6667V6H14V7.33333Z"
                          fill="#07A4FC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2447_1825">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <h4 className="font-semibold">Compony Pages</h4>
                </div>
                <p className="flex justify-center px-8 gap-6 text-sm">
                  <Link
                    onClick={() => handleClick(0)}
                    to="/company/contact"
                    className="hover:text-bluePirelly">
                    Contact Us
                  </Link>
                  <Link
                    onClick={() => handleClick(0)}
                    to="/company/about"
                    className="hover:text-bluePirelly">
                    About Us
                  </Link>
                  {/* <Link
                    onClick={() => handleClick(0)}
                    to="/company/privacy-policy"
                    className="hover:text-bluePirelly">
                    Privacy Policy
                  </Link>
                  <Link
                    onClick={() => handleClick(0)}
                    to="/company/terms"
                    className="hover:text-bluePirelly">
                    Terms Of Service
                  </Link> */}
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div className="flex-1 flex justify-end gap-10">
          <button className="text-bluePirelly py-3 px-6 whiteButton rounded-xl">
            <Link to="https://admin-bccd3.web.app/">Sign in</Link>
          </button>
          <button
            onClick={() => scrollToSection()}
            className="button text-sm blueButton">
            Book A Demo
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
