import carB from "../../assets/before.png";
import carA from "../../assets/after.png";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
  ReactCompareSliderHandle,
} from "react-compare-slider";

export default function BeforeAfter() {
  return (
    <section className="bg-zinc-100/30 flex-row items-center overflow-hidden h-full relative">
      <div className="text-center py-20">
        <h2 className="text-bluePirelly font-semibold">before & after</h2>
        <p className="text-4xl pt-5 font-bold">Experience The Transformation</p>
      </div>
      <div className="max-w-7xl pb-10 md:pb-32 flex justify-center mx-auto">
        <ReactCompareSlider
          handle={
            <ReactCompareSliderHandle
              buttonStyle={{ display: "none" }}
              linesStyle={{ height: "100%", width: 2 }}
            />
          }
          changePositionOnHover
          itemOne={<ReactCompareSliderImage src={carB} alt="Image one" />}
          itemTwo={<ReactCompareSliderImage src={carA} alt="Image two" />}
          style={{ width: "80%", height: "50%" }}
        />
      </div>
    </section>
  );
}
